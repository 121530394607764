class Session {
    static getInstance = (auth) => {
        return new Session(auth.user, auth.tokenId, auth.token, auth.passwordExpired);
    }
    constructor(email, tokenId, token, passwordExpired) {
        this.email = email;
        this.tokenId = tokenId;
        this.token = token;
        this.passwordExpired = passwordExpired;
    }


}

export default Session;