import React, { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { getHistory } from '../../api/subscription';
import AuthContext from '../../contexts/AuthContext';
import { constructTransactionTableData, getTransactionColumns } from '../../util/PaymentHistoryUtil';

const Confirmations = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const columns = getTransactionColumns();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        fetchPaymentHistory();
    }, []);

    const fetchPaymentHistory = async () => {
        try {
            const response = await getHistory(authContext);
            const transactions = response.data.transactions;
            prepareTransactionsTable(transactions);
        } catch (error) {
            // Handle error
        }
    };

    const prepareTransactionsTable = (transactions) => {
        const data = constructTransactionTableData(transactions);
        setIsLoading(false);
        setData(data);
    };

    return (
        <DataTable
            title={null}
            striped={true}
            dense
            columns={columns}
            data={data}
            progressPending={isLoading}
        />
    );
};

export default Confirmations;
