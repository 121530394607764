import React from 'react';
import { env } from '../config/properties';
import Display from '../constants/Display';

export const getLinItemJsx = (invoiceLineItems, planType, currency) => {
    const symbol = env[currency];
    if (invoiceLineItems && invoiceLineItems.length) {
        const lines = invoiceLineItems.map((line) => {
            if (line.lineItemType === planType) {
                return (
                    <React.Fragment key={line.entityId}>
                        <tr style={{ height: '26px' }}>
                            <td style={{ paddingRight: 15 }}>{getDescription(line)}</td>
                            <td>{symbol}{parseFloat(line.amount).toFixed(2)}</td>
                        </tr>

                    </React.Fragment>
                );
            }
            else return null;

        });
        return lines;
    }
    return [];

}
const getDescription = (line) => {
    if (line.lineItemType === Display.LINE_ITEM_TYPE_PLAN)
        return line.description
    else
        return `${line.description} (Selected:${line.quantity} seat/s)`
}
export const sumupDiscounts = (discountLineItems) => {
    let sum = 0.00;
    if (discountLineItems && discountLineItems.length) {
        discountLineItems.forEach(line => {
            sum += parseFloat(line.amount);
        });
    }

    return sum.toFixed(2);

}

