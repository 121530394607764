import React, { useState, useEffect, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { getHistory } from '../../api/subscription';
import AuthContext from '../../contexts/AuthContext';
import { constructInvoiceTableData, getInvoiceColumns } from '../../util/PaymentHistoryUtil';
import { getDataTable } from '../../util/TableUtil';

const Invoices = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const authContext = useContext(AuthContext);
    const columns = getInvoiceColumns();

    useEffect(() => {
        fetchPaymentHistory();
    }, []);

    const fetchPaymentHistory = () => {
        getHistory(authContext)
            .then(response => {
                const invoices = response.data.invoices;
                prepareInvoicesTable(invoices);
            })
            .catch(error => {
                console.error('Error fetching payment history:', error);
            });
    };

    const prepareInvoicesTable = (invoices) => {
        const data = constructInvoiceTableData(invoices);
        setIsLoading(false);
        setData(data);
    };

    return getDataTable(data, columns);
};

export default Invoices;
