import React, { useState, useRef, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import fsLogo from '../../images/fs-logo.svg';
import { resetPassword } from '../../api/account';
import { isSuccess } from '../../constants/Status';
import { addValidateClass, isValidPassword } from '../../util/Util';
import Confirm from '../common/Confirm';
import querystring from 'query-string';
import { waitForLoad } from '../../util/Loader';
import { Messages } from '../../constants/Messages';

const ResetPassword = (props) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validateForm, setValidateForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const confirmElement = useRef();
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const query = querystring.parse(location.search, { decode: true });
        const email = query.email;
        const resetToken = query.token;
        if (!email || !resetToken) {
            getDialog().status('This is an invalid reset link.', () => {
                history.push('/');
            });
        }
    }, [location, history]);

    const validateResetForm = () => {
        let isValid = password && confirmPassword && password === confirmPassword;
        if (password !== confirmPassword) {
            getDialog().status('Password is not matching. Please re-enter');
            isValid = false;
        }
        if (!isValidPassword(password)) {
            getDialog().status(Messages.INVALID_PASSWORD_MSG);
            isValid = false;
        }
        return isValid;
    };

    const getDialog = () => {
        return confirmElement.current;
    };

    const ResetPasswordRequest = (emailAddress, password, confirmPassword, resetToken) => {
        return { emailAddress, password, confirmPassword, resetToken };
    };

    const invokeResetPassword = () => {
        if (validateResetForm()) {
            getDialog().prompt('Are you sure you want to reset your password?', () => {
                setValidateForm(false);
                setIsLoading(true);
                const query = querystring.parse(location.search, { decode: true });
                const email = query.email;
                const resetToken = query.token;
                resetPassword(ResetPasswordRequest(email, password, confirmPassword, resetToken))
                    .then((response) => {
                        if (isSuccess(response.status)) {
                            setIsLoading(false);
                            getDialog().status('You have successfully reset the password.', () => {
                                history.push('/');
                            });
                        } else {
                            setIsLoading(false);
                            if (response.data.message) {
                                getDialog().status(response.data.message);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log('fs error', error);
                    });
            });
        } else {
            setValidateForm(true);
        }
    };

    const renderFn = () => {
        return (
            <React.Fragment>
                <div id="loginDiv">
                    <div className="mb-4"><img src={fsLogo} style={{ height: '108px', width: '268px', marginTop: '-21px', marginBottom: '-29px' }} /></div>
                    <form className="container-fluid" onSubmit={(e) => { e.preventDefault(); invokeResetPassword() }}>
                        <div className="form-group">
                            <label className="required-label">Enter password</label>
                            <input
                                type="password"
                                id="password"
                                className={`form-control form-control-sm ${addValidateClass(password, validateForm)}`}
                                value={password}
                                onChange={(e) => { setPassword(e.target.value); }} />
                        </div>
                        <div className="form-group">
                            <label className="required-label">Reenter password</label>
                            <input
                                type="password"
                                id="reEnterPassword"
                                className={`form-control form-control-sm ${addValidateClass(confirmPassword, validateForm)}`}
                                value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); }} />
                        </div>
                        <div className="field-note" style={{ marginTop: 11 }}>Password must be at least 8 characters and include an uppercase letter and a number.</div>
                        <div className="form-group" style={{ textAlign: 'center' }}>
                            <button className="btn btn-primary btn-sm" style={{ fontWeight: 'bold' }} onClick={invokeResetPassword}>Set password</button>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Link to="/" className="bluelink ml-2" ><strong>Login</strong></Link>
                        </div>
                    </form>
                </div>
                <Confirm show={false} ref={confirmElement} />
            </React.Fragment>
        );
    };

    return waitForLoad(isLoading, renderFn);
};

export default ResetPassword;
