import React, { useState, useRef, useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';
import fsLogo from '../../images/fs-logo.svg';
import { requestPasswordReset } from '../../api/account';
import Confirm from '../common/Confirm';
import AuthContext from '../../contexts/AuthContext';
import { isSuccess } from '../../constants/Status';
import { waitForLoad } from '../../util/Loader';
import { isValidEmail } from '../../util/Util';
import PropTypes from 'prop-types';

const ForgotPassword = (props) => {
    const confirmElement = useRef(null);
    const authContext = useContext(AuthContext);

    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const getDialog = () => {
        return confirmElement.current;
    }

    const goToLogin = () => {
        props.history.push('/');
    }

    const validate = () => {
        let isValid = true;
        if (!email) {
            getDialog().status('Enter email address');
            isValid = false;
        } else if (!isValidEmail(email)) {
            getDialog().status('Enter a valid email address');
            isValid = false;
        }
        return isValid;
    }

    const invokeResetPassword = () => {
        if (!validate()) return;

        setIsLoading(true);
        requestPasswordReset(email)
            .then((response) => {
                setIsLoading(false);
                if (isSuccess(response.status)) {
                    getDialog().status('A link will be emailed with password set/reset instructions if the account information you provided is valid. (Note: Please check your spam folder if you don’t receive the reset email.)', goToLogin);
                } else {
                    getDialog().status(response.data.message);
                }
            })
            .catch((error) => {
                console.log('fs error', error);
                setIsLoading(false);
            });
    }

    const renderFn = () => (
        <React.Fragment>
            <div id="loginDiv" className="pl-2 pr-2">
                <div className="mb-4">
                    <img src={fsLogo} style={{ height: '108px', width: '402px', marginTop: '-21px', marginBottom: '-29px' }} alt="Logo" />
                </div>
                <form className="container-fluid" onSubmit={(e) => { e.preventDefault(); invokeResetPassword(); }}>
                    <div className="form-group">
                        <label className="required-label">Enter your email</label>
                        <input
                            type="email"
                            id="email"
                            className="form-control form-control-sm"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <button type="button" className="btn btn-primary btn-sm w-100" onClick={invokeResetPassword}><strong>Submit</strong></button>
                    <div className="field-note mt-1">
                        An email with instructions on how to set/reset your password will be sent if the username you provided is valid.
                        <p>
                            Note: Please check your spam folder if you don’t receive the reset email. Also, please add our email to your contact list to ensure email delivery.
                        </p>
                    </div>
                    <div className="mt-5" style={{ textAlign: 'center' }}>
                        <Link to="/" className="blue-link"><strong>Back to login</strong></Link>
                    </div>
                </form>
            </div>
            <Confirm show={false} ref={confirmElement} />
        </React.Fragment>
    );

    return waitForLoad(isLoading, renderFn);
}

ForgotPassword.contextType = AuthContext;

ForgotPassword.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

export default withRouter(ForgotPassword);
