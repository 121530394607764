import * as Cookies from "js-cookie";

const sessionKey = 'fs_session';
export const setSessionCookie = (session) => {
    Cookies.remove(sessionKey);
    Cookies.set(sessionKey, session, { expires: 1 });
};

export const getSessionCookie = () => {
    const sessionCookie = Cookies.get(sessionKey);
    if (sessionCookie === undefined) {
        return null;
    } else {
        return JSON.parse(sessionCookie);
    }
};

export const hasSession = () => {
    return getSessionCookie() ? true : false;
}

export const clearSessionCookie = () => {
    Cookies.remove(sessionKey);
}