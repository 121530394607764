import React, { useState, useEffect, useContext, useRef } from 'react';
import { getProfile, updateProfile } from '../../api/account';
import { isSuccess } from '../../constants/Status';
import AuthContext from '../../contexts/AuthContext';
import { countryOptions, usStatesOptions } from '../../util/Countries';
import { waitForLoad } from '../../util/Loader';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';

const UpdateProfile = ({history}) => {
    const [state, setState] = useState({
        showStateDdl: true,
        country: 'US',
        firstName: null,
        lastName: null,
        company: null,
        industry: '',
        otherIndustry: null,
        address1: null,
        address2: null,
        city: null,
        state: 'AL',
        zip: null,
        isLoading: true
    });

    const authContext = useContext(AuthContext);
    const confirmElement = useRef();

    useEffect(() => {
        fetchUserProfile();
    }, []);

    const fetchUserProfile = () => {
        getProfile(authContext)
            .then((response) => {
                if (isSuccess(response.status)) {
                    const uiUser = response.data.uiUser;
                    const profile = uiUser.profile;
                    setProfile(profile);
                } else {
                    getDialog().status(response.data.message);
                }
            })
            .catch(e => {
                getDialog().status(e.message);
            })
            .finally(() => {
                setState(prevState => ({ ...prevState, isLoading: false }));
            });
    };

    const setProfile = (profile) => {
        if (!profile) profile = {};
        let industry = profile.industry ? profile.industry : state.industry;
        let otherIndustry = null;
        if (profile.industry) {
            industry = (profile.industry.includes('I_')) ? profile.industry : 'OTHER';
            otherIndustry = industry === 'OTHER' ? profile.industry : null;
        }

        setState(prevState => ({
            ...prevState,
            firstName: profile.firstName,
            lastName: profile.lastName,
            company: profile.company,
            industry: industry,
            otherIndustry: otherIndustry,
            address1: profile.address1,
            address2: profile.address2,
            city: profile.city,
            state: profile.state,
            country: profile.country,
            zip: profile.zip
        }));
    };

    const getDialog = () => {
        return confirmElement.current;
    };

    const industryChanged = (industry) => {
        setState(prevState => ({ ...prevState, industry }));
    };

    const goToDashboard = () => {
        history.push('/home');
    };

    const confirmProfileSave = () => {
        const confirm = confirmElement.current;
        confirm.prompt('Are you sure you want to save?', saveProfile);
    };

    const saveProfile = async () => {
        const updateProfileReq = {};
        updateProfileReq.emailId = authContext.user;
        const profile = {
            firstName: state.firstName,
            lastName: state.lastName,
            company: state.company,
            industry: state.industry === 'OTHER' ? state.otherIndustry : state.industry,
            address1: state.address1,
            address2: state.address2,
            city: state.city,
            state: state.state,
            country: state.country,
            zip: state.zip
        };
        updateProfileReq.profile = profile;
        updateProfile(updateProfileReq, authContext)
            .then((response) => {
                if (isSuccess(response.status)) {
                    const uiUser = response.data.uiUser;
                    const profile = uiUser.profile;
                    setProfile(profile);
                } else {
                    getDialog().status(response.data.message);
                }
            })
            .catch(e => {
                getDialog().status(e.message);
            });
    };

    const countryChanged = (country) => {
        const showStateDdl = country === 'US';
        setState(prevState => ({ ...prevState, country, showStateDdl }));
    };

    const getStateControl = () => {
        return state.showStateDdl ? (
            <div className="form-group row">
                <label className="col-md-3 col-form-label-sm" htmlFor="address">State</label>
                <div className="col-md-9">
                    <select
                        className="form-control form-control-sm"
                        id="stateDdl"
                        value={state.state}
                        onChange={e => setState(prevState => ({ ...prevState, state: e.target.value }))}
                    >
                        {usStatesOptions}
                    </select>
                </div>
            </div>
        ) : (
            <div className="form-group row">
                <label className="col-md-3 col-form-label-sm" htmlFor="address">State</label>
                <div className="col-md-9">
                    <input
                        type="text"
                        className="form-control form-control-sm"
                        id="state"
                        value={state.state}
                        onChange={e => setState(prevState => ({ ...prevState, state: e.target.value }))}
                    />
                </div>
            </div>
        );
    };

    return waitForLoad(state.isLoading, () => (
        <React.Fragment>
            <PanelTitle panelName="Update profile" />
            <div style={{ height: '400px', overflow: 'auto', borderBottom: '1px solid lightgray' }}>
                <div id="updateUsersDiv" style={{ paddingLeft: '10px', paddingTop: '9px', width: '50%' }}>
                    <form className="mt-2 container-fluid">
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="name">First name</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="name"
                                    value={state.firstName}
                                    onChange={(e) => setState(prevState => ({ ...prevState, firstName: e.target.value }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="name">Last name</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="lastName"
                                    value={state.lastName}
                                    onChange={(e) => setState(prevState => ({ ...prevState, lastName: e.target.value }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="email">Email</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="email"
                                    value={authContext.user}
                                    readOnly
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="email">Company</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="company"
                                    value={state.company}
                                    onChange={e => setState(prevState => ({ ...prevState, company: e.target.value }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="industry">Industry</label>
                            <div className="col-md-9">
                                <select
                                    className="form-control form-control-sm"
                                    id="industry"
                                    value={state.industry}
                                    onChange={e => industryChanged(e.target.value)}
                                >
                                    <option value="">Select an industry</option>
                                    <option value="I_ACCOUNTING">Accounting</option>
                                    <option value="I_AUTOMOTIVE">Automotive</option>
                                    <option value="I_COMPUTER_SOFTWARE_ENGINEERING">Computer Software/Engineering</option>
                                    <option value="I_CONSUMER_GOODS">Consumer Goods</option>
                                    <option value="I_EDUCATION">Education</option>
                                    <option value="I_FOOD_BEVERAGES">Food/Beverages</option>
                                    <option value="I_FINANCIAL_SERVICES">Financial Services</option>
                                    <option value="I_GOVERNMENT_ADMINISTRATION">Government Administration</option>
                                    <option value="I_HEALTHCARE">Healthcare</option>
                                    <option value="I_HOSPITALITY">Hospitality</option>
                                    <option value="I_INSURANCE">Insurance</option>
                                    <option value="OTHER">OTHER</option>
                                </select>
                                <div style={{ marginTop: 6, fontStyle: 'italic' }} className={state.industry === 'OTHER' ? '' : 'd-none'}>
                                    <div>Please specify the industry.</div>
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        id="otherIndustry"
                                        value={state.otherIndustry}
                                        onChange={e => setState(prevState => ({ ...prevState, otherIndustry: e.target.value }))}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="address">Address1</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="address1"
                                    value={state.address1}
                                    onChange={e => setState(prevState => ({ ...prevState, address1: e.target.value }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="address">Address2</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="address2"
                                    value={state.address2}
                                    onChange={e => setState(prevState => ({ ...prevState, address2: e.target.value }))}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="address">City</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="city"
                                    value={state.city}
                                    onChange={e => setState(prevState => ({ ...prevState, city: e.target.value }))}
                                />
                            </div>
                        </div>
                        {getStateControl()}
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="address">Country</label>
                            <div className="col-md-9">
                                <select
                                    className="form-control form-control-sm"
                                    id="country"
                                    onChange={(e) => countryChanged(e.target.value)}
                                    value={state.country}
                                >
                                    {countryOptions}
                                </select>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-3 col-form-label-sm" htmlFor="address">Zip</label>
                            <div className="col-md-9">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    id="zip"
                                    value={state.zip}
                                    onChange={e => setState(prevState => ({ ...prevState, zip: e.target.value }))}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div style={{ paddingLeft: '15px', paddingRight: '22px', width: '50%', paddingTop: '9px' }}>
                <button className="btn btn-sm btn-secondary" style={{ float: 'left' }} onClick={goToDashboard}>Cancel</button>
                <button style={{ float: 'right' }} className="btn btn-sm btn-primary" onClick={confirmProfileSave}>Update</button>
            </div>
            <Confirm ref={confirmElement} show={false} />
        </React.Fragment>
    ));
};

export default UpdateProfile;
