import React, { useState, useRef, useContext } from 'react';
import PanelTitle from '../common/PanelTitle';
import { cancelSubscription } from '../../api/subscription';
import AuthContext from '../../contexts/AuthContext';
import Confirm from '../common/Confirm';
import { isSuccess } from '../../constants/Status';
import { getSubscriptionEndDate } from '../../util/Util';
import { withRouter, useHistory, useLocation } from 'react-router';
import PropTypes from "prop-types";

const ConfirmCancellation = (props) => {
    const authContext = useContext(AuthContext);
    const history = useHistory();
    const location = useLocation();
    const { uiAccountSubscriptionInfo } = props.accountManagementData();
    const [subscriptionEndDate] = useState(getSubscriptionEndDate(uiAccountSubscriptionInfo));
    const [isLoading, setIsLoading] = useState(false);
    const [confirmEndDate, setConfirmEndDate] = useState(false);
    const confirmElement = useRef();

    const getDialog = () => {
        return confirmElement.current;
    }

    const invokeCancelSubscription = () => {
        if (confirmEndDate) {
            cancelSubscription(authContext).then((response) => {
                if (isSuccess(response.status)) {
                    history.push(`${props.accPath}/manageSubscription`);
                }
            });
        } else {
            getDialog().status('Please select the checkbox');
        }
    }

    const goToDashboard = () => {
        history.push('/home');
    }

    return (
        <React.Fragment>
            <PanelTitle panelName="Confirm cancellation" />
            <div style={{ paddingLeft: '10px', paddingTop: '9px' }}>
                <div className="subscription-cancel-details" style={{ textAlign: 'center' }}>
                    <div className="cancel-subscription-div full-width" style={{ width: '656px', paddingLeft: '41px' }}>
                        <input
                            id="cancel-subscription-checkbox"
                            type="checkbox"
                            style={{ marginRight: '3px' }}
                            checked={confirmEndDate}
                            onChange={(e) => { setConfirmEndDate(e.target.checked) }}
                        />
                        <span style={{ fontWeight: 'normal' }}>{`Cancel my subscription renewal on ${subscriptionEndDate} for all products and services on this account`}</span>
                    </div>
                    <div className="cancel-subscription-btn-div full-width" style={{ paddingLeft: '74px', paddingRight: '199px', marginTop: '25px' }}>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={goToDashboard} style={{ float: 'left' }}>Keep using FormulaSuite</button>
                        <button
                            className="btn btn-sm btn-secondary"
                            data-loading-text="<i class='fa fa-circle-o-notch fa-spin'></i> Cancel my subscription"
                            style={{ float: 'right' }} onClick={invokeCancelSubscription}>Cancel my subscription</button>
                    </div>
                </div>
            </div>
            <Confirm ref={confirmElement} show={false} />
        </React.Fragment>
    );
}

ConfirmCancellation.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    accountManagementData: PropTypes.func.isRequired,
    accPath: PropTypes.string.isRequired,
};

export default ConfirmCancellation; 
