import React, { useState, useEffect, useRef, useContext } from 'react';
import DataTable from 'react-data-table-component';
import { getSubscription, inviteUser, revokeUser } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import AuthContext from '../../contexts/AuthContext';
import { constructUserTableData, getUserTableColumns } from '../../util/InviteUserUtil';
import { waitForLoad } from '../../util/Loader';
import { isValidEmail } from '../../util/Util';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';

const UserManagement = () => {
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [inviteEmail, setInviteEmail] = useState(null);
    const [availableUsers, setAvailableUsers] = useState(0);

    const confirmElement = useRef(null);
    const { user, tokenId, token, subscriptionDetailResponse } = useContext(AuthContext);

    useEffect(() => {
        getSubscription(user, tokenId, token)
            .then(subscriptionResponse => {
                if (isSuccess(subscriptionResponse.status)) {
                    setIsLoading(false);
                    prepareUserTable(subscriptionResponse);
                } else {
                    setIsLoading(false);
                    getDialog().status(subscriptionResponse.data.message);
                }
            })
            .catch(e => {
                getDialog().status(e.message);
            });
    }, [user, tokenId, token]);

    const prepareUserTable = (subscriptionDetailResponse) => {
        if (!subscriptionDetailResponse) return;
        const { uiAccountSubscriptionInfo, invitedUsers } = subscriptionDetailResponse.data;
        const data = constructUserTableData(invitedUsers);
        setData(data);
        setAvailableUsers(uiAccountSubscriptionInfo.totalAdditionalUsers);
        setInvitedUsers(invitedUsers ? invitedUsers : []);
        setInviteEmail(null);
    };

    const deleteUser = (email) => {
        getDialog().prompt('Are you sure you want to delete?', () => {
            setIsLoading(true);
            revokeUser({ user, tokenId, token }, email)
                .then(response => {
                    if (isSuccess(response.status)) {
                        getSubscription(user, tokenId, token)
                            .then(subscriptionRes => {
                                prepareUserTable(subscriptionRes);
                            });
                    } else {
                        
                        getDialog().status(response.data.message);
                    }
                    setIsLoading(false);
                })
                .catch(e => {
                    setIsLoading(false);
                    getDialog().status(e.message);
                });
        });
    };

    const getDialog = () => {
        return confirmElement.current;
    };

    const validateInviteForm = () => {
        let isValid = true;
        if (!inviteEmail) isValid = false;
        if (!isValidEmail(inviteEmail)) {
            getDialog().status('You have entered an invalid email address. Please re-enter');
            isValid = false;
        }
        if (invitedUsers.length >= availableUsers) {
            getDialog().status('The number of users you have added has reached the maximum allowed for your subscription plan and add-on purchase/s, if any. To add more users, please upgrade your plan or purchase access for additional users in the Account Management page.');
            isValid = false;
        }
        return isValid;
    };

    const confirmInvite = () => {
        if (validateInviteForm()) {
            getDialog().prompt('You are inviting the user. Please make sure he has Microsoft Office 365 subscription. Are you sure you want to invite?', () => {
                setIsLoading(true);
                inviteUser({ user, tokenId, token }, inviteEmail)
                    .then(response => {
                        if (isSuccess(response.status)) {
                            getSubscription(user, tokenId, token)
                                .then(subResponse => {
                                    if (isSuccess(subResponse.status)) {
                                        prepareUserTable(subResponse);
                                    } else {
                                        
                                        getDialog().status(subResponse.data.message);
                                    }
                                    setIsLoading(false);
                                })
                                .catch(e => {
                                    setIsLoading(false);
                                    getDialog().status(e.message);
                                });
                        } else {
                            setIsLoading(false);
                            getDialog().status(response.data.message);
                        }
                    })
                    .catch(e => {
                        setIsLoading(false);
                        getDialog().status(e.message);
                    });
            });
        }
    };

    const userInvitedMessage = () => {
        const confirm = confirmElement.current;
        confirm.status('The user you invited will now have access to FormulaSuite. You can revoke access by deleting user.');
    };

    const confirmDelete = () => {
        const confirm = confirmElement.current;
        confirm.prompt('Are you sure you want to delete?');
    };

    const renderFn = () => {
        const columns = getUserTableColumns({ deleteUser });
        return (
            <React.Fragment>
                <PanelTitle panelName="Manage users" />
                <div style={{ paddingLeft: 10, paddingTop: 9, paddingRight: 10 }}>
                    <div className="alert alert-primary" role="alert" style={{ marginTop: 10, width: '50%' }}>
                        You have used <strong>{invitedUsers.length}</strong> of available <strong>{availableUsers}</strong> users.
                    </div>
                    <div style={{ width: 384, marginTop: 9, borderRadius: 6 }}>
                        <form className="form-inline mt-4" onSubmit={e => { e.preventDefault(); confirmInvite(); }}>
                            <div className="form-group">
                                <label style={{ fontWeight: 'bold' }} className='required-label'>User email</label>
                                <input
                                    type="text"
                                    id="inviteEmail"
                                    className="form-control form-control-sm ml-2"
                                    style={{ height: 25, width: 231 }}
                                    value={inviteEmail || ''}
                                    onChange={e => setInviteEmail(e.target.value)}
                                />
                                <button className="btn btn-primary btn-sm ml-2" style={{ height: 25, paddingTop: '2px' }} onClick={confirmInvite}>Invite</button>
                            </div>
                        </form>
                    </div>
                    <div style={{ width: '50%', height: '52vh' }} className='mt-3 overflow-auto'>
                        <DataTable title={null} striped={true} dense columns={columns} data={data} />
                    </div>
                </div>
                <Confirm show={false} ref={confirmElement} />
            </React.Fragment>
        );
    };

    return waitForLoad(isLoading, renderFn);
};

export default UserManagement;
