import React from 'react';
import { Spinner } from "react-bootstrap";

export const waitForLoad = (isLoading, renderFn) => {
    if (isLoading)
        return <div style={{ marginLeft: '50%', marginTop: 10, marginRight: 4 }}><Spinner animation="border" variant="primary" style={{ width: '1rem', height: '1rem' }} size="sm">

        </Spinner>
            <label className="mb-0 ml-2" style={{ color: "#007bff", verticalAlign: 'middle', fontSize: 16 }}  >Please wait..</label>
        </div>
    else {
        return renderFn();
    }
}