class UIBillingInfo {
    constructor(
        firstName,
        lastName,
        company,
        email,
        address1,
        address2,
        country,
        city,
        state,
        zip,
        cardType,
        year,
        month,
        number,
        verificationValue,
        firstSix,
        lastFour
    ) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.company = company;
        this.email = email;
        this.address1 = address1;
        this.address2 = address2;
        this.country = country;
        this.city = city;
        this.state = state;
        this.zip = zip;
        this.cardType = cardType;
        this.year = year;
        this.month = month;
        this.number = number;
        this.verificationValue = verificationValue;
        this.firstSix = firstSix;
        this.lastFour = lastFour;
    }
}

export default UIBillingInfo;