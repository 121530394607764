import React from 'react';
export const constructUserTableData = (users) => {
    if (!users)
        return [];
    const userObjects = users.map(user => {
        return {
            user: user
        };

    });

    return userObjects;
}

export const getUserTableColumns = (thisRef) => {
    const columns = [
        {
            name: 'User',
            sortable: true,
            selector: 'user'
        },
        {
            name: '',
            sortable: false,
            button: true,
            cell: row => <a className="blueLink" rel="noopener noreferrer" onClick={() => { thisRef.deleteUser(row.user) }}>Delete</a>
        }

    ];

    return columns;
}



export const constructTransactionTableData = (transactions) => {
    const transactionObjects = transactions.map(transaction => {
        const createdDate = new Date(transaction.createdAt);
        let createdDateStr = `${createdDate.getDate()}-${createdDate.toLocaleString('default', { month: 'long' })}-${createdDate.getFullYear()}`
        return {

            type: transaction.action,
            method: transaction.paymentMethod,
            status: transaction.status,
            amount: transaction.amount,
            date: createdDateStr
        };

    });

    return transactionObjects;
}

export const getTransactionColumns = () => {
    const columns = [
        {
            name: 'Type',
            sortable: false,
            selector: 'type',
        },
        {
            name: 'Method',
            selector: 'method',
            sortable: false,
            right: true,
        },
        {
            name: 'Status',
            selector: 'status',
            sortable: false,
            right: true,
        },
        {
            name: 'Amount',
            selector: 'amount',
            sortable: true,
            right: true,
        },
        {
            name: 'Date',
            selector: 'date',
            sortable: true,
            right: true,
        }
    ];

    return columns;
}