import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import Confirm from '../common/Confirm';
import AuthContext from '../../contexts/AuthContext';
import SubscriptionDetails from '../../model/SubscriptionDetails';
import UIBillingInfo from '../../model/UIBillingInfo';
import { waitForLoad } from '../../util/Loader';
import { countryOptions } from '../../util/Countries';
import { fetchBillingDetails, update, updateBillings, removeBillingInfo } from '../../api/subscription';
import { isValidField, addValidateClass } from '../../util/Util';
import { usStatesOptions } from '../../util/Countries';
import { isSuccess } from '../../constants/Status';
import { Messages } from '../../constants/Messages';

const BillingDetails = (props) => {
  const [state, setState] = useState({
    showStateDdl: true,
    isValidFirstName: true,
    hasOffice365: false,
    // billing details
    firstName: null,
    lastName: null,
    company: null,
    email: null,
    address1: null,
    address2: null,
    country: 'US',
    city: null,
    indState: 'AL',
    zip: null,
    // card details
    card: 'ADD_NEW_CARD_VALUE',
    cardType: null,
    year: 2022,
    month: 1,
    firstSix: null,
    lastFour: null,
    number: null,
    verificationValue: null,
    isLoading: true,
    validateForm: false,
    uiBillingInfo: null
  });

  const confirmElement = useRef();
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const updateBilling = props.updateBilling;
  const accData = props.accountManagementData();
  const isValidState = updateBilling || (accData && (accData.selectedPlan || accData.selectedSeats));

    useEffect(() => {
        getBillingDetails();
    }, []);

  const getBillingDetails = () => {
    fetchBillingDetails(authContext)
      .then(response => {
        setFormData(response.data);
      })
      .catch(e => {
        // handle error
      });
  };

  const setFormData = (data) => {
    const uiBillingInfo = data.billingInfo;
    if (uiBillingInfo) {
      setState(prevState => ({
        ...prevState,
        firstName: uiBillingInfo.firstName,
        lastName: uiBillingInfo.lastName,
        company: uiBillingInfo.company,
        email: uiBillingInfo.email,
        address1: uiBillingInfo.address1,
        address2: uiBillingInfo.address2,
        country: uiBillingInfo.country,
        city: uiBillingInfo.city,
        indState: uiBillingInfo.state,
        zip: uiBillingInfo.zip,
        number: uiBillingInfo.number,
        month: uiBillingInfo.month || 1,
        year: uiBillingInfo.year || 2022,
        verificationValue: uiBillingInfo.verificationValue,
        lastFour: uiBillingInfo.lastFour,
        card: uiBillingInfo.lastFour ? uiBillingInfo.lastFour : getMessages().ADD_NEW_CARD_VALUE,
        uiBillingInfo: uiBillingInfo,
        isLoading: false
      }));
    } else {
      setState(prevState => ({ ...prevState, isLoading: false }));
    }
  };

  const getDialog = () => {
    return confirmElement.current;
  };

  const toPurchaseSummary = () => {
    history.goBack();
  };

  const toAccountManagement = () => {
    history.push(`${props.accPath}/manageSubscription`);
  };

  const getMessages = () => {
    return BillingDetails.messages;
  };

  const getStateControl = () => {
    if (state.showStateDdl) {
      return (
        <div className="col-md-6" id="stateDdlDiv">
          <div className="form-group row">
            <label className="col-lg-3 col-form-label-sm required-label" htmlFor="state">State</label>
            <div className="col-lg-9">
              <select className={`form-control form-control-sm ${addValidateClass(state.indState, state.validateForm)}`}
                id="stateDdl"
                value={state.indState}
                onChange={(e) => { setState(prevState => ({ ...prevState, indState: e.target.value })) }}>
                {usStatesOptions}
              </select>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-md-6" id="stateInputDiv">
          <div className="form-group row">
            <label className="col-lg-3 col-form-label-sm required-label" htmlFor="state">State</label>
            <div className="col-lg-9">
              <input className={`form-control form-control-sm ${addValidateClass(state.indState, state.validateForm)}`}
                id="state"
                value={state.indState}
                onChange={(e) => { setState(prevState => ({ ...prevState, indState: e.target.value })) }} />
            </div>
          </div>
        </div>
      );
    }
  };

  const clearBillingInfo = () => {
    getDialog().prompt(Messages.DELETE_CONFIRMATION_MSG, () => {
      setState(prevState => ({ ...prevState, isLoading: true }));
      removeBillingInfo(authContext)
        .then(response => {
          getBillingDetails();
        })
        .catch(e => {
          getDialog().status(e.message);
        });
    });
  };

  const updateBillingDetails = () => {
    getDialog().prompt(Messages.SAVE_CONFIRMATION_MSG, () => {
      if (validateBillingAndCard()) {
        let updateBillingReq = {};
        updateBillingReq.emailId = authContext.user;
        const { firstName, lastName, company, email, address1, address2, city, indState, zip, country, number, month, year, verificationValue } = state;
        const billingInfo = new UIBillingInfo(firstName, lastName, company, email, address1, address2, country, city, indState, zip, null, year, month, number, verificationValue);
        updateBillingReq.uiBillingInfo = billingInfo;
        setState(prevState => ({ ...prevState, isLoading: true }));
        updateBillings(authContext, updateBillingReq)
          .then(response => {
            setState(prevState => ({ ...prevState, isLoading: false }));
            if (!isSuccess(response.status)) {
              getDialog().status(response.data.message);
            }
          })
          .catch(e => {
            getDialog().status(e.response.message);
          });
      }
    });
  };

  const countryChanged = (country) => {
    const showStateDdl = country === 'US';
    const state = country === 'US' ? 'AL' : null;
    setState(prevState => ({ ...prevState, country: country, indState: state, showStateDdl: showStateDdl }));
  };

  const selectCardChanged = (card) => {
    setState(prevState => ({
      ...prevState,
      number: null,
      month: 1,
      year: 2022,
      verificationValue: null,
      card: card
    }));
  };

  const validateBillingAndCard = () => {
    if (!state.hasOffice365 && !props.updateBilling) {
      getDialog().status('Please confirm that you have a valid Office 365 subscription by selecting checkbox');
      return false;
    }
    let reqFieldsPresent = true;
    if (!isValidField(state.firstName)) {
      reqFieldsPresent = false;
    }
    if (!isValidField(state.lastName)) {
      reqFieldsPresent = false;
    }
    if (!isValidField(state.address1)) {
      reqFieldsPresent = false;
    }
    if (!isValidField(state.country)) {
      reqFieldsPresent = false;
    }
    if (!isValidField(state.city)) {
      reqFieldsPresent = false;
    }
    if (!isValidField(state.indState)) {
      reqFieldsPresent = false;
    }
    if (!isValidField(state.zip)) {
      reqFieldsPresent = false;
    }
    if (state.card === getMessages().ADD_NEW_CARD_VALUE) {
      if (!isValidField(state.number)) {
        reqFieldsPresent = false;
      }
      if (!isValidField(state.month)) {
        reqFieldsPresent = false;
      }
      if (!isValidField(state.year)) {
        reqFieldsPresent = false;
      }
      if (!isValidField(state.verificationValue)) {
        reqFieldsPresent = false;
      }
    }
    if (!reqFieldsPresent) {
      getDialog().status('Please provide values for all the required fields');
      setState(prevState => ({ ...prevState, validateForm: true }));
    } else {
      if (state.validateForm) {
        setState(prevState => ({ ...prevState, validateForm: false }));
      }
    }
    return reqFieldsPresent;
  };

  const completePayment = () => {
    if (validateBillingAndCard()) {
      const accountManagementData = props.accountManagementData();
      const { uiAccountSubscriptionInfo, selectedPlan, selectedSeats } = accountManagementData;
      const uiBillingInfo = new UIBillingInfo(
        state.firstName,
        state.lastName,
        null,
        state.email,
        state.address1,
        state.address2,
        state.country,
        state.city,
        state.indState,
        state.zip,
        state.cardType,
        state.year,
        state.month,
        state.number,
        state.verificationValue,
        null,
        null
      );
      const subscriptionDetails = new SubscriptionDetails(
        authContext.user,
        selectedPlan,
        null,
        true,
        selectedSeats,
        isUseExistingCard() ? null : uiBillingInfo,
        accountManagementData.coupon,
        null
      );
      setState(prevState => ({ ...prevState, isLoading: true }));

      update(authContext, subscriptionDetails)
        .then(response => {
          setState(prevState => ({ ...prevState, isLoading: false }));
          if (isSuccess(response.status)) {
            getDialog().status('Your subscription plan has been updated',
              () => {
                history.push(`${props.accPath}/manageSubscription`);
                authContext.subscriptionDetailResponse = null;
                props.setAppState({ ...authContext });
              });
          } else {
            getDialog().status(response.data.message);
          }
        })
        .catch(error => {
          setState(prevState => ({ ...prevState, isLoading: false }));
          getDialog().status(error.message);
        });
    }
  };

  const isUseExistingCard = () => {
    return state.lastFour && state.card === getMessages().ADD_NEW_CARD_VALUE;
  };

  BillingDetails.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  BillingDetails.messages = {
    ADD_NEW_CARD_VALUE: "ADD_NEW_CARD_VALUE",
    ADD_NEW_CARD_NAME: "Add new card"
  };

  const renderFn = () => {
    const accountManagementData = props.accountManagementData();
    const { validateForm } = state;

    return (
      <>
        {!props.updateBilling && (
          <div id="panel-title">
            Billing &amp; Payment details
          </div>
        )}

        <div className="billing-scroller scrolling-container">
          <span className="billing-section-label d-inline-block">Billing details</span>
          <div id="billingDetailsDiv" className="overflow-hidden">
            <form className="w-100 mt-2 mb-0 container-fluid" id="billing-form">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label-sm required-label" htmlFor="firstName">First name</label>
                    <div className="col-lg-9">
                      <input
                        className={`form-control form-control-sm ${addValidateClass(state.firstName, validateForm)}`}
                        id="firstName"
                        value={state.firstName}
                        onChange={(e) => setState(prevState => ({ ...prevState, firstName: e.target.value }))}
                        aria-describedby="validationServer03Feedback" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label-sm required-label" htmlFor="lastName">Last name</label>
                    <div className="col-lg-9">
                      <input
                        className={`form-control form-control-sm ${addValidateClass(state.lastName, validateForm)}`}
                        id="lastName"
                        value={state.lastName}
                        onChange={(e) => setState(prevState => ({ ...prevState, lastName: e.target.value }))} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label-sm" htmlFor="company">Company</label>
                    <div className="col-lg-9">
                      <input
                        className="form-control form-control-sm"
                        id="company"
                        value={state.company}
                        onChange={(e) => setState(prevState => ({ ...prevState, company: e.target.value }))} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label-sm" htmlFor="email">Email</label>
                    <div className="col-lg-9">
                      <input
                        className="form-control form-control-sm"
                        id="email"
                        value={state.email}
                        onChange={(e) => setState(prevState => ({ ...prevState, email: e.target.value }))} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label-sm required-label" htmlFor="address1">Address1</label>
                    <div className="col-lg-9">
                      <input
                        className={`form-control form-control-sm ${addValidateClass(state.address1, validateForm)}`}
                        id="address1"
                        value={state.address1}
                        onChange={(e) => setState(prevState => ({ ...prevState, address1: e.target.value }))} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label-sm" htmlFor="address2">Address2</label>
                    <div className="col-lg-9">
                      <input
                        className="form-control form-control-sm"
                        id="address2"
                        value={state.address2}
                        onChange={(e) => setState(prevState => ({ ...prevState, address2: e.target.value }))} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label-sm required-label" htmlFor="city">City</label>
                    <div className="col-lg-9">
                      <input
                        className={`form-control form-control-sm ${addValidateClass(state.city, validateForm)}`}
                        id="city"
                        value={state.city}
                        onChange={(e) => setState(prevState => ({ ...prevState, city: e.target.value }))} />
                    </div>
                  </div>
                </div>
                {getStateControl()}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label-sm required-label" htmlFor="zip">Zip</label>
                    <div className="col-lg-9">
                      <input
                        className={`form-control form-control-sm ${addValidateClass(state.zip, validateForm)}`}
                        id="zip"
                        value={state.zip}
                        onChange={(e) => setState(prevState => ({ ...prevState, zip: e.target.value }))} />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label className="col-lg-3 col-form-label-sm required-label" htmlFor="country">Country</label>
                    <div className="col-lg-9">
                      <select
                        className={`form-control form-control-sm ${addValidateClass(state.country, validateForm)}`}
                        id="country" value={state.country}
                        onChange={(e) => setState(prevState => ({ ...prevState, country: e.target.value }))}>
                        {countryOptions}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <span className="billing-section-label d-inline-block">Card details</span>
          <div id="cardDetailsDiv">
            <form className="container-fluid mt-2" id="card-detail-form">
              <div className="row">
                <div className={`col-md-5 ${state.lastFour ? null : 'd-none'}`} id="pick-cards-section">
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label-sm" htmlFor="selectCard">Existing cards</label>
                    <div className="col-md-8">
                      <select
                        id="selectCard"
                        className="form-control form-control-sm"
                        style={{ width: '150px' }}
                        onChange={(e) => setState(prevState => ({ ...prevState, card: e.target.value }))}
                        value={state.card}
                      >
                        <option value="ADD_NEW_CARD_VALUE">Add a new card</option>
                        {state.lastFour && <option value={state.lastFour}>x-{state.lastFour}</option>}
                      </select>
                    </div>
                  </div>
                </div>
                <div className={`col-md-7 ${state.card !== 'ADD_NEW_CARD_VALUE' ? 'd-none' : null}`} id="add-new-card-section">
                  <div className="row form-group">
                    <label className="col-md-4 col-form-label-sm required-label" htmlFor="cardNumber">Card number</label>
                    <div className="col-md-8">
                      <input
                        id="cardNumber"
                        type="text"
                        className={`form-control form-control-sm ${addValidateClass(state.number, validateForm)}`}
                        style={{ width: '200px' }}
                        value={state.number}
                        onChange={(e) => setState(prevState => ({ ...prevState, number: e.target.value }))} />
                    </div>
                  </div>
                  <div className="row form-group">
                    <label className="col-md-4 col-form-label-sm required-label" htmlFor="cardNumber">Card Expiry Date</label>
                    <div className="col-md-8">
                      <select
                        id="card-expiry-month"
                        className="d-inline form-control form-control-sm"
                        style={{ width: '56px' }}
                        value={state.month}
                        onChange={(e) => setState(prevState => ({ ...prevState, month: e.target.value }))}>
                        {[...Array(12).keys()].map(i => (
                          <option key={i} value={i + 1}>{String(i + 1).padStart(2, '0')}</option>
                        ))}
                      </select>
                      <span className="card-expiry-seperator d-inline mx-1" style={{ width: '10px' }}>/</span>
                      <select
                        id="card-expiry-year"
                        className="d-inline form-control form-control-sm"
                        style={{ width: '59px' }}
                        value={state.year}
                        onChange={(e) => setState(prevState => ({ ...prevState, year: e.target.value }))}>
                        {[...Array(19).keys()].map(i => (
                          <option key={i + 2022} value={i + 2022}>{i + 2022}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-4 col-form-label-sm required-label" htmlFor="cvv">CVV/CVC</label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className={`form-control form-control-sm ${addValidateClass(state.verificationValue, validateForm)}`}
                        style={{ width: 67 }}
                        id="cvv"
                        value={state.verificationValue}
                        onChange={(e) => setState(prevState => ({ ...prevState, verificationValue: e.target.value }))} />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {!props.updateBilling ? (
          <>
            <div id="billingNavDiv" style={{ paddingLeft: 15 }}>
              <input
                type="checkbox"
                id="acceptOffice365Req"
                style={{ marginRight: 2 }}
                checked={state.hasOffice365}
                onChange={e => setState(prevState => ({ ...prevState, hasOffice365: e.target.checked }))} />
              <label htmlFor="acceptOffice365Req" style={{ display: 'inline' }}>
                <strong>I understand that I need a valid Microsoft Office 365 subscription to use FormulaSuite add-in</strong>
              </label>
            </div>
            <div
              id="navgationBtnsDiv"
              style={{ paddingLeft: '15px', paddingRight: '15px' }}>
              <button className="btn btn-sm btn-secondary" style={{ float: 'left' }} onClick={toPurchaseSummary}>Back</button>
              <button className="btn btn-sm btn-primary" style={{ float: 'right' }} onClick={completePayment}>{`Pay $${accountManagementData.amountDue.toFixed(2)}`}</button>
            </div>
          </>
        ) : (
          <div id="updateBillingControls"
            style={{ paddingLeft: '15px', paddingRight: '15px', paddingTop: '3px' }}>
            <button className="btn btn-sm btn-secondary mr-3" style={{ float: 'left' }} onClick={toAccountManagement}>Cancel</button>
            <button className="btn btn-sm btn-danger" style={{ float: 'left' }} onClick={clearBillingInfo}>Clear Billing Info</button>
            <button className="btn btn-sm btn-primary" style={{ float: 'right' }} onClick={updateBillingDetails}>Update</button>
          </div>
        )}
        <Confirm show={false} ref={confirmElement} />
      </>
    );
  };

  if (!isValidState) {
    return <Redirect to={`${props.accPath}/manageSubscription`} />;
  }

  return waitForLoad(state.isLoading, renderFn);
};

export default BillingDetails;