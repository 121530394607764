import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import fsLogo from '../../images/fs-logo.svg';
import { AddinLoginRequest, AddinSignUpRequest } from '../../requests/AccountRequests';
import Auth from '../../model/Auth';
import { login, signup } from '../../api/account';
import { getSubscription } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import Confirm from '../common/Confirm';
import { addValidateClass, isValidEmail, isValidPassword } from '../../util/Util';
import Session from '../../model/Session';
import { setSessionCookie } from '../../util/SessionUtil';
import { waitForLoad } from '../../util/Loader';
import { Messages } from '../../constants/Messages';

const Signup = (props) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validateForm, setValidateForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const confirmElement = useRef(null);

    const validateSignupForm = () => {
        if (!email || !password || !confirmPassword) {
            setValidateForm(true);
            return false;
        }
        if (!isValidEmail(email)) {
            confirmElement.current.status('Please enter a valid email address');
            return false;
        }
        if (!isValidPassword(password)) {
            confirmElement.current.status(Messages.INVALID_PASSWORD_MSG);
            return false;
        }
        if (password !== confirmPassword) {
            confirmElement.current.status('Passwords are not matching. Please re-enter');
            return false;
        }
        return true;
    };

    const signupUser = async () => {
        if (!validateSignupForm()) {
            return;
        }
        setIsLoading(true);
        try {
            const response = await signup(AddinSignUpRequest(email, password));
            if (isSuccess(response.status)) {
                let addinLoginRequest = AddinLoginRequest(email, password, null);
                const loginResponse = await login(addinLoginRequest);
                const { token, token_id } = loginResponse.headers;
                const isPasswordExpired = loginResponse.data.isPasswordExpired;
                const auth = new Auth(email, token_id, token);
                const session = Session.getInstance(auth);
                setSessionCookie(session);
                const subscriptionDetailResponse = await getSubscription(email, token_id, token);
                if (isSuccess(subscriptionDetailResponse.status)) {
                    auth.subscriptionDetailResponse = subscriptionDetailResponse;
                } else {
                    confirmElement.current.status(subscriptionDetailResponse.data.message);
                }
                setIsLoading(false);
                props.setAppState(auth);
            } else {
                setIsLoading(false);
                confirmElement.current.status(response.data.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.log('fs error', error);
        }
    };

    const renderFn = () => (
        <React.Fragment>
            <div id="loginDiv" style={{ height: 442 }}>
                <div className="mb-4"><img src={fsLogo} style={{ height: '108px', width: '268px', marginTop: '-21px', marginBottom: '-29px' }} alt="fs logo" /></div>
                <form className="container-fluid" onSubmit={(e) => { e.preventDefault(); }}>
                    <div className="form-group">
                        <label className="required-label">Enter your email</label>
                        <input
                            type="email"
                            id="email"
                            style={{ textTransform: 'lowercase' }}
                            className={`form-control form-control-sm ${addValidateClass(email, validateForm)}`}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label className="required-label">Enter password</label>
                        <input
                            type="password"
                            id="password"
                            className={`form-control form-control-sm ${addValidateClass(password, validateForm)}`}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label className="required-label">Reenter password</label>
                        <input
                            type="password"
                            id="reEnterPassword"
                            className={`form-control form-control-sm ${addValidateClass(confirmPassword, validateForm)}`}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </div>
                    <div className="field-note" style={{ marginTop: 11 }}>Password must be at least 8 characters and include an uppercase letter and a number.</div>
                    <div className="form-group" style={{ textAlign: 'center' }}>
                        <button className="btn btn-primary btn-sm" style={{ fontWeight: 'bold' }} onClick={signupUser}>Start 14-day Trial</button>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ borderTop: '1px solid lightgray', fontWeight: 'bold', color: '#6e6d6d' }}>Already have an account?</span>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Link to="/" className="bluelink ml-2"><strong>Login</strong></Link>
                    </div>
                </form>
            </div>
            <Confirm show={false} ref={confirmElement} />
        </React.Fragment>
    );

    return waitForLoad(isLoading, renderFn);
};

export default Signup;
