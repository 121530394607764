import Display from "../constants/Display";

export const addValidateClass = (field, validateForm) => {
    if (validateForm && !isValidField(field))
        return 'border-red';
    else
        return null;
}

export const isValidField = (field) => {
    return (field != null && field !== '' && field !== undefined)
}

export const isTrial = (status) => {
    return status === Display.STATUS_TRIAL;
}

export const isInactive = (status) => {
    return Display.CANCELLED === status || Display.NON_RENEWING === status;
}


export const isValidEmail = (email) => {
    if (/^[\w-_\.+]*[\w-_\.]\@([\w-_]+\.)+([\w]+)$/.test(email)) {
        return (true)
    }
    return (false);
}

export const isValidPassword = (password) => {
    if (/(?=.*[0-9])(?=.*[A-Z])(?=\S+$).{8,}/.test(password)) {
        return true
    }
    return false;
}
function isValidDate(dateString) {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date);
}

export const getSubscriptionEndDate = (uiAccountSubscriptionInfo) => {
    if (!uiAccountSubscriptionInfo)
        return null;
    else if (!uiAccountSubscriptionInfo.trialPeriodEndAt && !uiAccountSubscriptionInfo.currentPeriodEndAt)
        return 'Not renewing';
    else {
        const nextChargeAt = isTrial(uiAccountSubscriptionInfo.statusDisplayName) ?
            new Date(uiAccountSubscriptionInfo.trialPeriodEndAt) : new Date(uiAccountSubscriptionInfo.currentPeriodEndAt);
        if(isValidDate(nextChargeAt)){
             return  `${nextChargeAt.getDate()}-${nextChargeAt.toLocaleString('default', { month: 'long' })}-${nextChargeAt.getFullYear()}`;
            
        }else{
            return '--';
        }
        
    }

}

