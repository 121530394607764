export const doPost = async (instance, url, request, headers) => {
    try {
        const response = await instance.post(url, request, headers ? headers : {});
        if (null === response || undefined === response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }
}


export const getAuthHeaders = (auth) => {
    const headers = { 'token_id': auth.tokenId, 'token': auth.token };
    return headers;
}