
import React, { useEffect, useState } from "react";
import './App.css';
import Login from './components/auth/Login';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import Dashboard from "./components/layout/Dashboard";
import Signup from "./components/auth/Signup";
import AuthContext from "./contexts/AuthContext";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import { clearSessionCookie, getSessionCookie, hasSession, setSessionCookie } from "./util/SessionUtil";
import { getSubscription } from "./api/subscription";
import Auth from "./model/Auth";
import { isSuccess } from "./constants/Status";
import { waitForLoad } from "./util/Loader";
import ChangePassword from "./components/auth/ChangePassword";
import { validatePasswordExpiry } from "./api/account";

const App = () => {
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true); //auth is an object with class definition in Auth.js

  const setAppState = (auth) => {
    setAuth(auth);
  }

  const logout = () => {
    clearSessionCookie();
    setAuth(null);
  }

  useEffect(() => {
    if (hasSession()) {
      const session = getSessionCookie();
      if (!auth || !auth.subscriptionDetailResponse) {
        getSubscription(session.email, session.tokenId, session.token).then(response => {
          let authInst = Auth.getInstance(session)
          authInst.subscriptionDetailResponse = response;
          if (session.passwordExpired) {
            let passwordValidationReq = {};
            setLoading(true);
            passwordValidationReq.emailAddress = session.email;
            validatePasswordExpiry(passwordValidationReq, authInst).then(response => {
              const isPasswordExpired = (response && response.data) ? response.data.passwordExpired : false;
              session.passwordExpired = isPasswordExpired;
              authInst.passwordExpired = isPasswordExpired;
              setSessionCookie(session);
              setAuth(authInst);
            });
          }
          setAuth(authInst);
          setLoading(false);
        }).catch(e => {
          logout();
          setLoading(false);
        });
      } else {
        setLoading(false);
      }

    } else {
      setLoading(false);
    }
  }, []);




  const renderFn = () => {
    // debugger;
    if (hasSession() && auth) {
      return (
        <BrowserRouter>
          <AuthContext.Provider value={auth}>
            <Dashboard user={auth.user} logoutFn={logout} setAppState={setAppState} passwordExpired={auth.passwordExpired} />
          </AuthContext.Provider>
        </BrowserRouter>
      );
    }
    else {
      return (
        <BrowserRouter>
          <Switch>
            <Route path="/signup" >
              <Signup setAppState={setAppState} />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword setAppState={setAppState} />
            </Route>
            <Route path="/password/reset">
              <ResetPassword setAppState={setAppState} />
            </Route>
            <Route path="/" exact>
              <Login setAppState={setAppState} />
            </Route>

            <Redirect to="/" />
          </Switch>
        </BrowserRouter>
      );
    }
  }

  return waitForLoad(loading, renderFn);


}


export default App;
