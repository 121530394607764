import React, { useState, useRef, useContext } from 'react';
import { ChangePasswordRequest } from '../../requests/AccountRequests';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';
import { changePassword } from '../../api/account';
import { isSuccess } from '../../constants/Status';
import { waitForLoad } from '../../util/Loader';
import { addValidateClass } from '../../util/Util';
import AuthContext from '../../contexts/AuthContext';
import { getSessionCookie, setSessionCookie } from '../../util/SessionUtil';

const ChangePassword = (props) => {
    const confirmElement = useRef(null);
    const authContext = useContext(AuthContext);

    const [currentPassword, setCurrentPassword] = useState(null);
    const [newPassword, setNewPassword] = useState(null);
    const [reEnterPassword, setReEnterPassword] = useState(null);
    const [validateForm, setValidateForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const getDialog = () => {
        return confirmElement.current;
    }

    const validateResetForm = () => {
        let isValid = currentPassword && newPassword && reEnterPassword;
        if (!isValid) {
            setValidateForm(true);
            getDialog().status('Please provide required fields');
        } else if (newPassword !== reEnterPassword) {
            setValidateForm(true);
            getDialog().status('Passwords are not matching. Please re-enter');
            isValid = false;
        } else {
            setValidateForm(false);
        }
        return isValid;
    }

    const invokePasswordReset = () => {
        if (validateResetForm()) {
            const changePasswordReq = ChangePasswordRequest(authContext.user, currentPassword, newPassword, reEnterPassword);

            getDialog().prompt('Are you sure you want to reset password?', () => {
                setIsLoading(true);
                changePassword(authContext, changePasswordReq)
                    .then((response) => {
                        if (isSuccess(response.status)) {
                            setCurrentPassword(null);
                            setNewPassword(null);
                            setReEnterPassword(null);
                            setValidateForm(false);
                            setIsLoading(false);

                            if (authContext.passwordExpired) {
                                const session = getSessionCookie();
                                authContext.passwordExpired = false;
                                session.passwordExpired = false;
                                setSessionCookie(session);
                                props.setAppState({ ...authContext });
                            }
                        } else {
                            getDialog().status(response.data.message);
                            setIsLoading(false);
                        }
                    })
                    .catch((e) => {
                        setIsLoading(false);
                        getDialog().status(e.message);
                    });
            });
        }
    }

    const renderFn = () => {
        return (
            <React.Fragment>
                <PanelTitle panelName="Change password" />
                <div style={{ paddingLeft: '10px', paddingTop: '9px', width: '70%' }}>
                    {props.passwordExpired && (
                        <div id="pwdExpiryMsg" className="alert alert-danger">
                            Your password has expired. Please reset your password to continue.
                        </div>
                    )}
                    <form className="mt-2 container-fluid" onSubmit={(e) => { e.preventDefault(); invokePasswordReset() }}>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label-sm" htmlFor="password">Current password</label>
                            <div className="col-md-6">
                                <input
                                    type="password"
                                    className={`form-control form-control-sm ${addValidateClass(currentPassword, validateForm)}`}
                                    id="password"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label-sm" htmlFor="new-password">New password</label>
                            <div className="col-md-6">
                                <input
                                    type="password"
                                    className={`form-control form-control-sm ${addValidateClass(newPassword, validateForm)}`}
                                    id="new-password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-md-4 col-form-label-sm" htmlFor="re-enter-password">Re-enter new password</label>
                            <div className="col-md-6">
                                <input
                                    type="password"
                                    className={`form-control form-control-sm ${addValidateClass(reEnterPassword, validateForm)}`}
                                    id="re-enter-password"
                                    value={reEnterPassword}
                                    onChange={(e) => setReEnterPassword(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row" style={{ marginTop: '-9px' }}>
                            <div className="col-md-12" style={{ color: 'gray', fontStyle: 'italic', paddingLeft: '15px', marginBottom: '10px' }}>
                                Password must be at least 8 characters and include an uppercase letter and a number.
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4" />
                            <div className="col-md-6">
                                {props.passwordExpired && (
                                    <button className="btn btn-secondary btn-sm" onClick={props.logoutFn} style={{ marginRight: '60px' }}>Logout</button>
                                )}
                                <button className="btn btn-primary btn-sm" onClick={invokePasswordReset}>Reset password</button>
                            </div>
                        </div>
                    </form>
                </div>
                <Confirm show={false} ref={confirmElement} />
            </React.Fragment>
        );
    }

    return waitForLoad(isLoading, renderFn);
}

export default ChangePassword;
