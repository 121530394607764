import React, { useState, useRef, useImperativeHandle, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';

const Confirm = React.forwardRef((props, ref) => {
    const [state, setState] = useState({
        show: props.show || false,
        body: props.body || '',
        okFunction: props.okFunction || null,
        cancelFunction: props.cancelFunction || null,
        okLabel: props.okLabel || 'OK',
        cancelLabel: props.cancelLabel || 'Cancel',
        title: props.title || null,
        backdrop: props.backdrop || 'static',
        keyboard: props.keyboard,
        showCancel: props.showCancel
    });

    const okRef = useRef(null);
    const cancelRef = useRef(null);

    const handleClose = () => {
        if (state.cancelFunction) state.cancelFunction();
        setState(prevState => ({ ...prevState, show: false }));
    };

    const handleOK = () => {
        if (state.okFunction) state.okFunction();
        setState(prevState => ({ ...prevState, show: false }));
    };

    useImperativeHandle(
        ref,
        () =>
          ({
            prompt : (body, okFunction, cancelFunction, okLabel = 'Yes', cancelLabel, backdrop, title, keyboard) => {
                callSetState(body, okFunction, cancelFunction, okLabel, cancelLabel, title, backdrop, keyboard, true, () => {
                    okRef.current.focus();
                });
            },
            status : (body, okFunction, cancelFunction, okLabel, cancelLabel, backdrop, title, keyboard) => {
                callSetState(body, okFunction, cancelFunction, okLabel, cancelLabel, title, backdrop, keyboard, false, () => {
                    okRef.current.focus();
                });
            }
          })
      );

    const callSetState = (body, okFunction, cancelFunction, okLabel, cancelLabel, title, backdrop, keyboard, showCancel, callBack) => {
        setState({
            show: true,
            showCancel: showCancel,
            body: body || '',
            okFunction: okFunction || null,
            cancelFunction: cancelFunction || null,
            okLabel: okLabel || 'OK',
            cancelLabel: cancelLabel || 'Cancel',
            title: title || null,
            backdrop: backdrop || 'static',
            keyboard: keyboard
        }, () => {
            callBack();
        });
        setModalHeader();
    };

    const [modalHeader, setModalHeader] = useState('');
    useEffect(() => {
        setModalHeader((state.title) ? (
            <Modal.Header>
                <Modal.Title>{state.title}</Modal.Title>
            </Modal.Header>
        ) : '');
    }, [state.title]);

    return (
        <Modal className="seqnc-modal"
            show={state.show}
            onHide={handleClose}
            backdrop={state.backdrop}
            keyboard={state.keyboard}
        >
            {modalHeader}

            <Modal.Body dangerouslySetInnerHTML={{ __html: state.body }} />
            <Modal.Footer>
                {state.showCancel ? (
                    <Button ref={cancelRef}
                        variant="secondary"
                        size="sm"
                        onClick={handleClose}>
                        {state.cancelLabel}
                    </Button>
                ) : ''}
                <Button
                    ref={okRef}
                    variant="primary"
                    size="sm"
                    onClick={handleOK}>{state.okLabel}</Button>
            </Modal.Footer>
        </Modal>
    );
});

export default Confirm;
