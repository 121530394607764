const Status = {
    HTTP_OK: 200,
    HTTP_OK1: 201,

};
const isSuccess = function (code) {
    return code && (code === Status.HTTP_OK || code === Status.HTTP_OK1)
}

Object.freeze(Status);

export { Status, isSuccess };