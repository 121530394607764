import React, { useState } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { Route, Switch, useRouteMatch } from 'react-router';
import BillingsAndInvoices from '../other/BillingsAndInvoices';
import ReduceUsers from '../users/ReduceUsers';
import BillingDetails from './BillingDetails';
import ConfirmCancellation from './ConfirmCancellation';
import ConfirmReactivation from './ConfirmReactivation';
import ManageSubscription from './ManageSubscription';
import PurchaseSummary from './PurchaseSummary';
import PurchaseSummaryAddon from './PurchaseSummaryAddon';

const ManageAccount = (props) => {
    const [accountManagementData, setAccountManagementData] = useState(null);
    let { path, url } = useRouteMatch();

    const getAccountManagementData = () => {
        return accountManagementData;
    }
    return (
        <Switch>
            <Route path={`${path}/manageSubscription`} exact>
                <ManageSubscription
                    accPath={path}
                    setAccountManagementData={setAccountManagementData}
                    accountManagementData={getAccountManagementData}
                    setAppState={props.setAppState}
                />

            </Route>
            <Route path={`${path}/purchaseSummary`} exact>
                <PurchaseSummary
                    accPath={path}
                    setAccountManagementData={setAccountManagementData}
                    accountManagementData={getAccountManagementData} />
            </Route>
            <Route path={`${path}/billingDetails`} exact>
                <BillingDetails
                    accPath={path}
                    setAccountManagementData={setAccountManagementData}
                    accountManagementData={getAccountManagementData}
                    setAppState={props.setAppState}
                />
            </Route>
            <Route path={`${path}/reduceUsers`} exact>
                <ReduceUsers
                    accPath={path}
                    setAccountManagementData={setAccountManagementData}
                    accountManagementData={getAccountManagementData}
                />
            </Route>
            <Route path={`${path}/updateBillingDetails`} exact>
                <BillingsAndInvoices
                    accPath={path}
                    setAccountManagementData={setAccountManagementData}
                    accountManagementData={getAccountManagementData}
                />
            </Route>
            <Route path={`${path}/confirmCancellation`} exact >
                <ConfirmCancellation
                    accPath={path}
                    setAccountManagementData={setAccountManagementData}
                    accountManagementData={getAccountManagementData}
                    setAppState={props.setAppState}
                />
            </Route>
            <Route path={`${path}/confirmReactivation/:purchase?`} >
                <ConfirmReactivation
                    accPath={path}
                    setAccountManagementData={setAccountManagementData}
                    accountManagementData={getAccountManagementData}
                    setAppState={props.setAppState}
                />
            </Route>

            <Route path={path} exact>
                <ManageSubscription
                    accPath={path}
                    setAccountManagementData={setAccountManagementData}
                    accountManagementData={getAccountManagementData}
                />
            </Route>

        </Switch>
    );
}

export default ManageAccount;