import axios from 'axios';
import { env } from '../config/properties';
import { doPost, getAuthHeaders } from './common';

export const subscription = axios.create({
    baseURL: `${env.url}/fs-subscription-service/addin`
});

export const createsubscription = async (auth, subscriptionDetails) => {
    try {
        const response = await subscription.post('/subscription/create', subscriptionDetails, {
            headers: getAuthHeaders(auth)
        });
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }
}

export const getSubscription = async (emailId, tokenId, token) => {
    try {
        const response = await subscription.get(`/subscription/customer/${emailId}`, {
            headers: { 'token_id': tokenId, 'token': token }
        });
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }

}

export const cancelSubscription = async (auth) => {
    let cancelRequest = {};
    cancelRequest.emailId = auth.user;
    const response = await doPost(subscription, '/subscription/cancel', cancelRequest, {
        headers: getAuthHeaders(auth)
    });
    return response;
}

export const reactivateSubscription = async (auth) => {
    let reactivateRequest = {};
    reactivateRequest.emailId = auth.user;
    try {
        const response = await doPost(subscription, '/subscription/reactivate', reactivateRequest, {
            headers: getAuthHeaders(auth)
        });
        return response;
    } catch (e) {
        return e.response;
    }

}
export const estimate = async (auth, subscriptionDetails) => {
    const headers = { 'token_id': auth.tokenId, 'token': auth.token };
    try {
        const response = await subscription.post('/subscription/estimate', subscriptionDetails, { headers: headers });
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }

}

export const update = async (auth, subscriptionDetails) => {

    const headers = { 'token_id': auth.tokenId, 'token': auth.token };
    try {
        const response = await subscription.post('/subscription/update', subscriptionDetails, { headers: headers });
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }
}

export const fetchBillingDetails = async (auth) => {
    const headers = { 'token_id': auth.tokenId, 'token': auth.token };
    try {
        const response = await subscription.get(`/subscription/billing/${auth.user}`, { headers: headers });
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }
}

export const updateBillings = async (auth, updateBillingDetailsReq) => {
    const headers = { 'token_id': auth.tokenId, 'token': auth.token };
    try {
        const response = await subscription.post('/billing/update', updateBillingDetailsReq, { headers: headers });
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }
}

export const removeBillingInfo = async (auth) => {
    const headers = { 'token_id': auth.tokenId, 'token': auth.token };
    try {
        const response = await subscription.post('/subscription/billing/clear', { 'emailId': auth.user }, { headers: headers });
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }
}


export const getHistory = async (auth) => {
    const headers = { 'token_id': auth.tokenId, 'token': auth.token };
    try {
        const response = await subscription.get(`/subscription/payments/history/${auth.user}`, { headers: headers });
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }
}

export const changeOwner = async (auth, changeOwnerRequest) => {
    const response = await doPost(subscription, '/subscription/changeOwner', changeOwnerRequest, {
        headers: getAuthHeaders(auth)
    });
    return response;
}

export const inviteUser = async (auth, user) => {
    let rbacRequest = {};
    rbacRequest.invitedBy = auth.user;
    rbacRequest.invitedEmailIds = [user];
    const response = await doPost(subscription, '/subscription/rbac/invite', rbacRequest, {
        headers: getAuthHeaders(auth)
    });
    return response;
}

export const revokeUser = async (auth, revokeEmail) => {
    let revokeRequest = {};
    revokeRequest.emailId = auth.user;
    revokeRequest.revokeEmailIds = [revokeEmail];
    const response = await doPost(subscription, '/subscription/rbac/revoke', revokeRequest, {
        headers: getAuthHeaders(auth)
    });
    return response;
}

export const getInviters = async (auth) => {
    const headers = { 'token_id': auth.tokenId, 'token': auth.token };
    try {
        const response = await subscription.get(`/subscription/inviters/${auth.user}`, { headers: headers });
        return response;
    } catch (e) {
        if (null === e.response || undefined === e.response) {
            return { data: { message: 'Service might be down.Please try again later' } }
        }
        return e.response;
    }
}






