import React, { useState, useEffect, useRef, useContext } from 'react';
import { getInviters } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import AuthContext from '../../contexts/AuthContext';
import { getDataTable } from '../../util/TableUtil';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';

const AccountAccess = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [inviters, setInviters] = useState([]);
    const confirmElement = useRef(null);
    const authContext = useContext(AuthContext);
    const columns = getInvitersTableColumns();

    useEffect(() => {
        const fetchInviters = async () => {
            try {
                const response = await getInviters(authContext);
                if (isSuccess(response.status)) {
                    const inviters = constructInvitersTableData(response.data);
                    setIsLoading(false);
                    setInviters(inviters);
                } else {
                    getDialog().status(response.data.message);
                }
            } catch (e) {
                getDialog().status(e.message);
            }
        };

        fetchInviters();
    }, [authContext]);

    const getDialog = () => {
        return confirmElement.current;
    };

    const constructInvitersTableData = (inviters) => {
        if (!inviters) return [];
        return inviters.map(inviter => ({
            inviter: inviter
        }));
    };

    const getInvitersTableColumns = () => {
        return [
            {
                name: 'Invited by',
                sortable: true,
                selector: 'inviter'
            }
        ];
    };

    return (
        <React.Fragment>
            <PanelTitle panelName="Account access" />
            <div id="updateUsersDiv" style={{ paddingLeft: '10px', paddingTop: '9px' }}>
                <div id="invite-list-div" style={{ maxWidth: 320 }}>
                    {getDataTable(inviters, columns)}
                </div>
            </div>
            <Confirm ref={confirmElement} show={false} />
        </React.Fragment>
    );
};

export default AccountAccess;
