import axios from 'axios';
import { env } from '../config/properties';
import { doPost, getAuthHeaders } from './common'
export const account = axios.create({
    baseURL: `${env.url}/account-management/addin`
});

export const login = async (addinLoginRequest) => {
    const response = await doPost(account, '/login', addinLoginRequest);
    return response;
}

export const signup = async (addinSignupRequest) => {
    return await doPost(account, '/signup', addinSignupRequest);
}

export const updateProfile = async (updateProfileRequest, auth) => {
    const headers = { 'token_id': auth.tokenId, 'token': auth.token };
    return await doPost(account, '/updateProfile', updateProfileRequest, { headers: headers });
}

export const getProfile = async (auth) => {
    const response = await account.post(`/profile`, { emailId: auth.user }, {
        headers: getAuthHeaders(auth)
    });
    return response;
}

export const invokeOTP = async (auth, otpRequest) => {
    const response = await account.post(`/otp`, otpRequest, {
        headers: getAuthHeaders(auth)
    });
    return response;
}

export const changePassword = async (auth, changePasswordRequest) => {
    const response = await account.post('/changepassword', changePasswordRequest, {
        headers: getAuthHeaders(auth)
    });

    return response;
}

export const requestPasswordReset = async (email) => {
    try {
        if (email) {
            email = email.trim().toLowerCase();
        }
        const response = await account.get(`/password/req/reset/${email}/`);
        return response;
    } catch (e) {
        return e.response;
    }

}

export const resetPassword = async (resetPasswordRequest) => {
    const response = await doPost(account, '/password/reset', resetPasswordRequest);
    return response;
}

export const validatePasswordExpiry = async (validatePasswordRequest, auth) => {
    const response = await doPost(account, '/validatePasswordExpiry', validatePasswordRequest, {
        headers: getAuthHeaders(auth)
    });
    return response;
}







