import React, { useState, useRef, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { inviteUser, invokeOTP } from '../../api/account';
import { isSuccess } from '../../constants/Status';
import { OTPRequest } from '../../requests/AccountRequests';
import { ChangeOwnerRequest } from '../../requests/SubscriptionRequests';
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';
import AuthContext from '../../contexts/AuthContext';
import { changeOwner } from '../../api/subscription';
import { waitForLoad } from '../../util/Loader';
import { isValidEmail } from '../../util/Util';

const ChangeAccountOwner = (props) => {
    const confirmElement = useRef(null);
    const auth = useContext(AuthContext);
    const history = useHistory();
    const [password, setPassword] = useState(null);
    const [newOwnerEmail, setNewOwnerEmail] = useState(null);
    const [newOwnerEmail1, setNewOwnerEmail1] = useState(null);
    const [otp, setOtp] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const getDialog = () => {
        return confirmElement.current;
    }

    const goToDashboard = () => {
        history.push('/home');
    }

    const transferAccount = () => {
        if (validate(true)) {
            getDialog().prompt('You are transferring your subscription. You will lose access to FormulaSuite post transfer.', () => {
                const request = ChangeOwnerRequest(newOwnerEmail, auth.user, otp, password);
                setIsLoading(true);
                changeOwner(auth, request).then((response) => {
                    setIsLoading(false);
                    if (isSuccess(response.status)) {
                        auth.subscriptionDetailResponse = null;
                        props.setAppState({ ...auth });
                        getDialog().status('Account transferred successfully', goToDashboard);
                    } else {
                        getDialog().status(response.data.message);
                    }
                }).catch(e => {
                    setIsLoading(false);
                    getDialog().status(e.message);
                });
            });
        }
    }

    const validate = (transferAccount) => {
        let valid = newOwnerEmail === newOwnerEmail1;
        if (!valid) {
            getDialog().status("Email and confirm Email are not matching. Please re-enter");
            return false;
        }
        if (!isValidEmail(newOwnerEmail)) {
            getDialog().status("Please enter a valid email address");
            return false;
        }
        if (transferAccount && !otp) {
            getDialog().status("Please enter OTP");
            return false;
        }
        return true;
    }

    const sendOtp = () => {
        if (validate(false)) {
            getDialog().prompt('Are you sure you want to send OTP?', () => {
                setIsLoading(true);
                invokeOTP(auth, OTPRequest(true, auth.user, newOwnerEmail)).then((response) => {
                    setIsLoading(false);
                    if (isSuccess(response.status)) {
                        getDialog().status('OTP sent successfully');
                    }
                }).catch(e => {
                    getDialog().status(e.message);
                });
            });
        }
    }

    const renderFn = () => (
        <React.Fragment>
            <PanelTitle panelName="Change account owner" />
            <div id="updateUsersDiv" style={{ paddingLeft: 10, paddingTop: 9, width: '82%' }}>
                <form className="mt-2 container-fluid" id="change-owner-form" onSubmit={e => e.preventDefault()}>
                    <div className="form-group row">
                        <label className="col-md-6 col-form-label-sm required-label" htmlFor="password">Enter your current password</label>
                        <div className="col-md-6">
                            <input
                                type="password"
                                className="form-control form-control-sm"
                                id="password"
                                value={password || ''}
                                onChange={e => setPassword(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-md-6 col-form-label-sm required-label" htmlFor="newOwnerEmail">Enter email address of new owner</label>
                        <div className="col-md-6">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="newOwnerEmail"
                                value={newOwnerEmail || ''}
                                onChange={e => setNewOwnerEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-md-6 col-form-label-sm required-label" htmlFor="newOwnerEmail1">Reenter email address of new owner</label>
                        <div className="col-md-6">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="newOwnerEmail1"
                                value={newOwnerEmail1 || ''}
                                onChange={e => setNewOwnerEmail1(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-md-6 col-form-label-sm required-label" htmlFor="otp">Enter one-time password (OTP)</label>
                        <div className="col-md-4">
                            <button className="btn btn-success btn-sm" onClick={sendOtp}>Send OTP to new owner</button>
                        </div>
                        <div className="col-md-2">
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="otp"
                                value={otp || ''}
                                onChange={e => setOtp(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="field-note">Note: The OTP will be sent to the new owner's email address. Please request and enter here for validation.</div>
                </form>
                <div style={{ paddingLeft: 15, paddingRight: 15, marginTop: '13px' }}>
                    <Link to="/home" className="btn btn-sm btn-secondary" style={{ float: 'left' }} onClick={goToDashboard}>Cancel</Link>
                    <button className="btn btn-sm btn-primary" style={{ float: 'right' }} onClick={transferAccount}>Next</button>
                </div>
            </div>
            <Confirm show={false} ref={confirmElement} />
        </React.Fragment>
    );

    return waitForLoad(isLoading, renderFn);
};

export default ChangeAccountOwner;
