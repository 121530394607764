
class Auth {
    static getInstance = (session) => {
        return new Auth(session.email, session.tokenId, session.token, null, false);
    }
    constructor(user, tokenId, token, subscriptionDetailResponse, passwordExpired) {
        this.user = user;
        this.tokenId = tokenId;
        this.token = token;
        this.subscriptionDetailResponse = subscriptionDetailResponse;
        this.passwordExpired = passwordExpired;

    }


}

export default Auth;