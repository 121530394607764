import React, { useContext, useEffect, useRef } from 'react';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { createsubscription, getSubscription } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import AuthContext from '../../contexts/AuthContext';
import SubscriptionDetails from '../../model/SubscriptionDetails';
import { waitForLoad } from '../../util/Loader';
import Confirm from '../common/Confirm';

const Home = (props) => {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [subscriptionDetailResponse, setSubscriptionDetailResponse] = useState(null);
    const auth = useContext(AuthContext);
    const confirmElement = useRef();
    const getSubscriptionDetails = () => {
        getSubscription(auth.user, auth.tokenId, auth.token).then(response => {
            if (isSuccess(response.status)) {
                setSubscriptionDetailResponse(response);
            } else {
                setLoading(false);
                setErrorMessage(response.data.message);

            }
        })
    }
    useEffect(() => {
        if (!(subscriptionDetailResponse)
            || !(subscriptionDetailResponse.data)
            || !(subscriptionDetailResponse.data.uiAccountSubscriptionInfo)
        ) {
            if (auth.subscriptionDetailResponse
                && auth.subscriptionDetailResponse.data
                && auth.subscriptionDetailResponse.data.uiAccountSubscriptionInfo) {
                setSubscriptionDetailResponse(auth.subscriptionDetailResponse);
            }
            else {
                getSubscriptionDetails();
            }
        }


    }, []);

    useEffect(() => {
        if (subscriptionDetailResponse) {
            setLoading(false);
            auth.subscriptionDetailResponse = subscriptionDetailResponse;
            props.setAppState({ ...auth });
        }
    }, [subscriptionDetailResponse]);



    const startFreeTrial = () => {
        getDialog().prompt('Are you sure you want to start your 14 day free trial?', () => {
            setLoading(true);
            const subscriptionDetails = new SubscriptionDetails(
                auth.user,
                null,
                null,
                false,
                0,
                null,
                null,
                true
            );
            createsubscription(auth, subscriptionDetails).then(response => {
                if (isSuccess(response.status)) {
                    getSubscriptionDetails();
                }

            });
        })

    }

    const getDialog = () => {
        return confirmElement.current;
    }
    const renderFn = () => {

        if (subscriptionDetailResponse) {
            const response = subscriptionDetailResponse.data;
            return (
                <>
                    <div>
                        {(!response.activeSubscription && response.rbacSubscription) ?
                            (<div className="alert alert-primary" role="alert">
                                Your account is associated as a guest or additional paid user for another account, and does not have Admin privileges.
                                <p> <Link className="dashboard-link blueLink" to="/accountAccess">Click here to view account associations.</Link> (Only available options in the dashboard are enabled.) You can set up your own account with full Admin access and ability to add guest and additional paid users. <Button onClick={startFreeTrial} variant="primary" size="sm">Start free trial</Button></p>
                            </div>) : null
                        }

                        {(!response.rbacSubscription && !response.uiAccountSubscriptionInfo) ?
                            (<div className="alert alert-primary" role="alert">
                                You don't have an active subscription to FormulaSuite. <Link className="btn btn-sm btn-primary" to="/manageAccount/confirmReactivation/purchase">Buy subscription</Link>
                            </div>) : null
                        }


                        {
                            response.activeSubscription ? (
                                <div className=" d-flex fsTails">

                                    <div className="fsTail"><Link className="dashboard-link blueLink" to="/manageAccount/manageSubscription">Manage Subscription</Link></div>
                                    <div className="fsTail"><Link className="dashboard-link blueLink" to="/manageUsers">Manage Users</Link></div>
                                    <div className="fsTail"><Link className="dashboard-link blueLink" to="/manageAccount/updateBillingDetails">Update Billings/<br />View Transactions</Link></div>
                                </div>) : null
                        }

                        <div className="d-flex fsTails">
                            {(!response.activeSubscription && response.uiAccountSubscriptionInfo) ? (<div className="fsTail"><Link className="dashboard-link blueLink" to="/manageAccount/manageSubscription">Reactivate</Link></div>) : null}
                            {(response.uiAccountSubscriptionInfo) ? (<div className="fsTail"><Link className="dashboard-link blueLink" to="/updateProfile">Update Profile</Link></div>) : null}
                            {response.activeSubscription ? (<div className="fsTail"><Link className="dashboard-link blueLink" to="/changeAccountOwner">Change Account Owner</Link></div>) : null}
                            {(response.uiAccountSubscriptionInfo) ? (<div className="fsTail"><a className="dashboard-link blueLink" href="https://help.formulasuite.com/" target="_blank">Help</a></div>) : null}
                        </div>
                    </div >
                    <Confirm show={false} ref={confirmElement} />
                </>
            );
        }
        else {
            return <div>{errorMessage}</div>
        }
    }

    return waitForLoad(loading, renderFn);

}



export default Home;