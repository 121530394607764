import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import PanelTitle from '../common/PanelTitle';
import { waitForLoad } from '../../util/Loader';
import { getSubscription } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import AccountManagementData from '../../model/AccountManagentData';
import AuthContext from '../../contexts/AuthContext';

const ConfirmReactivation = ({ accPath, setAccountManagementData, accountManagementData, setAppState }) => {
    const history = useHistory();
    const { purchase } = useParams();
    const auth = useContext(AuthContext);

    const acctData = accountManagementData();
    const uiAccountSubscriptionInfo = acctData ? acctData.uiAccountSubscriptionInfo : null;

    const [selectedPlan, setSelectedPlan] = useState(uiAccountSubscriptionInfo ? uiAccountSubscriptionInfo.planCode : 'formulasuite_monthly_subscription');
    const [additionalUsers, setAdditionalUsers] = useState(uiAccountSubscriptionInfo ? uiAccountSubscriptionInfo.quantity : 0);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (acctData == null) {
            setIsLoading(true);
            getSubscriptionDetails();
        }
    }, []);

    const getSubscriptionDetails = () => {
        const { user, tokenId, token } = auth;
        getSubscription(user, tokenId, token)
            .then(response => {
                if (isSuccess(response.status)) {
                    const { uiAccountSubscriptionInfo } = response.data;
                    setIsLoading(false);
                    const accountManagementData = new AccountManagementData(uiAccountSubscriptionInfo);
                    setAccountManagementData({ ...accountManagementData });
                    auth.subscriptionDetailResponse = response;
                    setAppState({ ...auth });
                } else {
                    setIsLoading(false);
                console(response.data.message);
                }
            })
            .catch(e => {
                console(e.message);
            });
    };

    const goToPurchaseSummary = () => {
        const acctData = accountManagementData();
        acctData.selectedPlan = selectedPlan;
        acctData.selectedSeats = additionalUsers;
        setAccountManagementData({ ...acctData });
        history.push(`${accPath}/purchaseSummary`);
    };

    const goToManageSubscription = () => {
        const acctData = accountManagementData();
        if (purchase) {
            history.push('/home');
        } else {
            history.push(`${accPath}/manageSubscription`);
        }
    };

    return waitForLoad(isLoading, () => (
        <React.Fragment>
            <PanelTitle panelName="Confirm reactivation" />
            <div style={{ paddingLeft: 10, paddingTop: 9, width: '53%' }}>
                <form className="mt-2 container-fluid" onSubmit={e => e.preventDefault()}>
                    <div className="form-group row">
                        <label className="col-md-4 col-form-label-sm" htmlFor="name">Select a plan</label>
                        <div className="col-md-8">
                            <select
                                id="planSelector"
                                className="form-control form-control-sm"
                                style={{ width: '100%' }}
                                value={selectedPlan}
                                onChange={e => setSelectedPlan(e.target.value)}
                            >
                                <option value="formulasuite_monthly_subscription">Formulasuite monthly subscription</option>
                                <option value="formulasuite_annual_subscription">Formulasuite annual subscription</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-md-4 col-form-label-sm" htmlFor="email">Additional users </label>
                        <div className="col-md-8">
                            <input
                                id="noOfUsers"
                                type="number"
                                min="0"
                                className="integerField quantity-field"
                                style={{ border: '1px solid lightgray', color: '#495057' }}
                                value={additionalUsers}
                                onChange={e => setAdditionalUsers(Math.abs(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <button type="button" className="btn btn-sm btn-secondary" onClick={goToManageSubscription}>Cancel</button>
                        </div>
                        <div className="col-sm-6" style={{ textAlign: 'right' }}>
                            <button type="button" className="btn btn-sm btn-primary" onClick={goToPurchaseSummary}>Next</button>
                        </div>
                    </div>
                </form>
            </div>
        </React.Fragment>
    ));
};

ConfirmReactivation.propTypes = {
    accountManagementData: PropTypes.func.isRequired,
    setAccountManagementData: PropTypes.func.isRequired,
    accPath: PropTypes.string.isRequired,
    setAppState: PropTypes.func.isRequired
};

export default ConfirmReactivation;
