import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import ChangePassword from '../auth/ChangePassword';

import ContentArea from './ContentArea';
import Footer from './Footer';
import NavBar from './NavBar';

const Dashboard = (props) => {
    const { instance, accounts } = useMsal();
    const content = props.passwordExpired ? <ChangePassword logoutFn={props.logoutFn} setAppState={props.setAppState} passwordExpired={props.passwordExpired} /> : <ContentArea setAppState={props.setAppState} passwordExpired={props.passwordExpired} />;

    return (
        <React.Fragment>
            <NavBar user={props.user} logoutFn={props.logoutFn} passwordExpired={props.passwordExpired} />
            {content}
            <Footer />
        </React.Fragment>
    );
};


export default Dashboard;