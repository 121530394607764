import React, { useState, useEffect, useRef, useContext } from 'react';
import { withRouter } from 'react-router';
import { update } from '../../api/subscription';
import { isSuccess } from '../../constants/Status';
import AuthContext from '../../contexts/AuthContext';
import { waitForLoad } from '../../util/Loader';
import Confirm from '../common/Confirm';
import PropTypes from "prop-types";
import Display from '../../constants/Display';
import SubscriptionDetails from '../../model/SubscriptionDetails';

const ReduceUsers = (props) => {
    const { match, location, history } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState(null);
    const confirmElement = useRef(null);
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const accData = props.accountManagementData();
        const initialRows = prepareTableRowData(accData.seatsToReduce, accData.invitedUsers);
        setRows(initialRows);
        setIsLoading(false);
    }, [props]);

    const getDialog = () => {
        return confirmElement.current;
    };

    const goToManageSubscription = () => {
        props.history.push(`${props.accPath}/manageSubscription`);
    };

    const getReduceUserTableRows = () => {
        return rows.map((row, index) => (
            <tr key={index}>
                <td>
                    <input
                        type="checkbox"
                        id={`checkbox${index}`}
                        checked={row.include ? 'checked' : null}
                        onChange={e => userCheckboxChanged(e, index)}
                    />
                </td>
                <td>
                    <select
                        className="form-control form-control-sm"
                        onChange={(e) => userDdlChanged(e, index)}>
                        {getOptionsHtml(row)}
                    </select>
                </td>
            </tr>
        ));
    };

    const getOptionsHtml = (row) => {
        const optionData = row.optionData;
        const optionHTML = [];
        optionHTML.push(<option key="null" value={null}></option>);
        for (let key in optionData) {
            if (optionData[key]) {
                optionHTML.push(
                    <option key={key} value={key} selected={key === row.selectedUser}>
                        {key}
                    </option>
                );
            } else {
                optionHTML.push(
                    <option key={key} value={key} disabled style={{ fontStyle: 'italic' }}>
                        {key}
                    </option>
                );
            }
        }
        return optionHTML;
    };

    const prepareTableRowData = (noOfUsersToReduce, inviteUsers) => {
        if (inviteUsers === null) return null;
        const optionData = {};
        inviteUsers.forEach(user => {
            optionData[user] = true;
        });

        const rows = [];
        for (let index = 0; index < noOfUsersToReduce; index++) {
            rows[index] = {
                include: false,
                selectedUser: null,
                optionData: { ...optionData }
            };
        }
        return rows;
    };

    const userDdlChanged = (e, index) => {
        const updatedRows = [...rows];
        const row = updatedRows[index];
        const previousUser = row.selectedUser;
        const currentUser = e.target.value;

        if (previousUser !== currentUser) {
            updatedRows.forEach((r, i) => {
                if (previousUser !== null) r.optionData[previousUser] = true;
                if (currentUser !== null && i !== index) r.optionData[currentUser] = false;
            });
            row.selectedUser = currentUser;
            setRows(updatedRows);
        }
    };

    const userCheckboxChanged = (e, index) => {
        const updatedRows = [...rows];
        const row = updatedRows[index];
        row.include = !row.include;
        setRows(updatedRows);
    };

    const validateForm = () => {
        const rowsToValidate = rows;
        let valid = true;

        rowsToValidate.forEach(row => {
            if (!row.include || !row.selectedUser) {
                valid = false;
                return false;
            }
        });

        if (!valid) {
            getDialog().status('Select user/s from the dropdown and check the box to confirm');
        }
        return valid;
    };

    const removeUsers = () => {
        const accData = props.accountManagementData();
        const seatsToReduce = accData.seatsToReduce;

        if (validateForm()) {
            getDialog().prompt(
                `You are suspending access to ${seatsToReduce} users. Are you sure you want to continue?`,
                reduceUsers
            );
        }
    };

    const reduceUsers = () => {
        const accData = props.accountManagementData();
        const uiAccountSubscriptionInfo = accData.uiAccountSubscriptionInfo;
        const noOfSeats = uiAccountSubscriptionInfo.noOfSeats;
        const isTrial = uiAccountSubscriptionInfo.statusDisplayName === Display.STATUS_TRIAL;
        const revokeUsers = rows.map(row => (row.include && row.selectedUser ? row.selectedUser : null)).filter(Boolean);

        const subscriptionDetails = new SubscriptionDetails(
            authContext.user,
            accData.selectedPlan,
            null,
            !isTrial,
            noOfSeats - accData.seatsToReduce,
            null,
            null,
            null,
            revokeUsers
        );

        setIsLoading(true);

        update(authContext, subscriptionDetails)
            .then(response => {
                setIsLoading(false);
                if (isSuccess(response.status)) {
                    getDialog().status('Plan is updated successfully', goToManageSubscription);
                } else {
                    getDialog().status(response.data.message);
                }
            })
            .catch(error => {
                setIsLoading(false);
                getDialog().status(error.message);
            });
    };

    return waitForLoad(isLoading, () => {
        const accData = props.accountManagementData();

        return (
            <React.Fragment>
                <div id="panel-title">Manage user list</div>
                <div style={{ paddingLeft: 10, paddingTop: 9, paddingRight: 10 }}>
                    <div style={{ fontWeight: 'bold' }}>
                        You have reduced the number of users in your plan by {accData.seatReducedBy}. Please select the users for whom you’d like to suspend access. Select a user from the dropdown and check the box to confirm.
                    </div>
                    <table id="invite-list-table" style={{ width: '55%' }}>
                        <tbody>{getReduceUserTableRows()}</tbody>
                    </table>
                    <div style={{ width: '50%', textAlign: 'center', float: 'none', marginTop: '16px' }}>
                        <button className="sq-primary-btn" style={{ float: 'none' }} onClick={removeUsers}>
                            Confirm changes
                        </button>
                    </div>
                </div>
                <Confirm show={false} ref={confirmElement} />
            </React.Fragment>
        );
    });
};

ReduceUsers.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    accountManagementData: PropTypes.func.isRequired,
    accPath: PropTypes.string.isRequired
};

export default withRouter(ReduceUsers);
