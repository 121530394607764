import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { getSubscription, update, reactivateSubscription } from '../../api/subscription';
import AuthContext from '../../contexts/AuthContext';
import { waitForLoad } from '../../util/Loader';
import PropTypes from "prop-types";
import Confirm from '../common/Confirm';
import PanelTitle from '../common/PanelTitle';
import AccountManagementData from '../../model/AccountManagentData';
import Display from '../../constants/Display';
import SubscriptionDetails from '../../model/SubscriptionDetails';
import { isSuccess } from '../../constants/Status';
import { getSubscriptionEndDate, isInactive, isTrial } from '../../util/Util';

const ManageSubscription = ({ accPath, setAccountManagementData, setAppState, accountManagementData }) => {
    ManageSubscription.propTypes = {
        accPath: PropTypes.string.isRequired,
        setAccountManagementData: PropTypes.func.isRequired,
        setAppState: PropTypes.func.isRequired,
        accountManagementData: PropTypes.func.isRequired
    };
    
    ManageSubscription.messages = {
        SUBSCRIPTION_UPDATED: 'Your subscription plan has been updated',
        SUBSCRIPTION_WILL_UPDATE_AT_EOP: 'Your subscription plan will be updated at the end of current subscription period'
    };
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const context = useContext(AuthContext);

    const [showPlanDdl, setShowPlanDdl] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [selectedPlanInDdl, setSelectedPlanInDdl] = useState(null);
    const [showAddonInput, setShowAddonInput] = useState(false);
    const [subscriptionResponse, setSubscriptionResponse] = useState(null);
    const [uiAccountSubscriptionInfo, setUiAccountSubscriptionInfo] = useState(null);
    const [showPrompt, setShowPrompt] = useState(false);
    const [paidUserCount, setPaidUserCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [nextChargeAt, setNextChargeAt] = useState('');

    const confirmElement = useRef(null);

    const plans = [
        {
            id: 'formulasuite_annual_subscription',
            displayName: 'Formulasuite annual subscription'
        },
        {
            id: 'formulasuite_monthly_subscription',
            displayName: 'Formulasuite monthly subscription'
        }
    ];

    useEffect(() => {
        if (accountManagementData) {
            setAccountManagementData(null);
        }
        getSubscriptionDetails();
    }, []);

    const getSubscriptionDetails = () => {
        const { user, tokenId, token } = context;
        setIsLoading(true);
    
        getSubscription(user, tokenId, token)
            .then(response => {
                if (isSuccess(response.status)) {
                    const { uiAccountSubscriptionInfo } = response.data;
                    let nextChargeAtString = getSubscriptionEndDate(uiAccountSubscriptionInfo);
                    setSubscriptionResponse(response);
                    setPaidUserCount(uiAccountSubscriptionInfo.noOfSeats);
                    setSelectedPlan(uiAccountSubscriptionInfo.planCode);
                    setSelectedPlanInDdl(uiAccountSubscriptionInfo.planCode);
                    setUiAccountSubscriptionInfo(uiAccountSubscriptionInfo);
                    setNextChargeAt(nextChargeAtString);
                    
                    const accountManagementData = new AccountManagementData(uiAccountSubscriptionInfo);
                    setAccountManagementData({ ...accountManagementData });
                    context.subscriptionDetailResponse = response;
                    setAppState({ ...context });
                } else {
                    getDialog().status(response.data.message);
                }
                setIsLoading(false);
            })
            .catch(e => {
                setIsLoading(false);
                console.error('Error fetching subscription details:', e.message);
            });
    };
    

    const changeQuantityClicked = () => {
        const confirm = confirmElement.current;
        const { uiAccountSubscriptionInfo } = subscriptionResponse.data;
        const paidUserCountDb = uiAccountSubscriptionInfo.noOfSeats;
        if (paidUserCountDb === paidUserCount) {
            confirm.status('Please modify number of users and then click change');
        } else if (isInactive(uiAccountSubscriptionInfo.subscriptionStatus)) {
            getDialog().status('Modifications to your subscription is not allowed as your subscription is in Inactive state. First you need to reactivate your account by clicking on Reactivate link');
        } else if (paidUserCountDb < paidUserCount) {
            if (isTrial(uiAccountSubscriptionInfo.statusDisplayName)) {
                upgradeTrialPlan();
            } else {
                const accountManagementData = new AccountManagementData(uiAccountSubscriptionInfo, selectedPlan, paidUserCount);
                setAccountManagementData({ ...accountManagementData });
                history.push(`${accPath}/purchaseSummary`);
            }
        } else if (paidUserCountDb > paidUserCount) {
            const diff = paidUserCountDb - paidUserCount;
            getDialog().prompt(`You are reducing number of seats by ${diff}. Any users you would have assigned to these seat/s will loose access to FormulaSuite. Are you sure you want to continue?`, reduceSeats);
        }
    };

    useEffect(() => {
        if(subscriptionResponse!=null && showPlanDdl && selectedPlanInDdl !== selectedPlan) {
        changePlan();
        }
    }, [selectedPlanInDdl, subscriptionResponse, showPlanDdl]);

    const planSelectorChanged = (e) => {
        setSelectedPlanInDdl(e.target.value);
    };

    const reduceSeats = () => {
        let invitedUsers = subscriptionResponse.data.invitedUsers || [];
        const { uiAccountSubscriptionInfo } = subscriptionResponse.data;
        const paidUserCountDb = uiAccountSubscriptionInfo.noOfSeats;
        if (invitedUsers.length > paidUserCount) {
            const accountManagementData = new AccountManagementData();
            accountManagementData.invitedUsers = invitedUsers;
            accountManagementData.seatsToReduce = (invitedUsers.length - uiAccountSubscriptionInfo.noOfGuestSeats) - paidUserCount;
            accountManagementData.seatReducedBy = paidUserCountDb - paidUserCount;
            accountManagementData.selectedPlan = getSelectedPlan();
            accountManagementData.uiAccountSubscriptionInfo = { ...uiAccountSubscriptionInfo };
            setAccountManagementData({ ...accountManagementData });
            history.push(`${accPath}/reduceUsers`);
        } else {
            downgradePlan();
        }
    };

    const changePlan = () => {
        const { uiAccountSubscriptionInfo } = subscriptionResponse.data;
        const isTrial = uiAccountSubscriptionInfo.statusDisplayName === Display.STATUS_TRIAL;
        let isDowngrade = selectedPlan === 'formulasuite_annual_subscription' && selectedPlanInDdl === 'formulasuite_monthly_subscription';
        if (isInactive(uiAccountSubscriptionInfo.subscriptionStatus)) {
            getDialog().status('Modifications to your subscription is not allowed as your subscription is in Inactive state. First you need to reactivate your account by clicking on Reactivate link', resetPlan);
        } else if (isDowngrade) {
            const msg = isTrial ? 'You are downgrading your plan. Are you sure you want to proceed?' :
                'You are downgrading your plan. Changes will be effective after current billing period. Are you sure you want to proceed?';
            getDialog().prompt(msg, downgradePlan, resetPlan);
        } else {
            if (isTrial) {
                getDialog().prompt('Are you sure you want to update your plan?', upgradeTrialPlan, resetPlan);
            } else {
                getDialog().prompt('Your change/s will be effective upon confirmation and payment update. Do you want to continue?', upgradePlan, resetPlan);
            }
        }
    };

    const downgradePlan = async () => {
        const { uiAccountSubscriptionInfo } = subscriptionResponse.data;
        const isTrial = uiAccountSubscriptionInfo.statusDisplayName === Display.STATUS_TRIAL;
        const selectedPlan = getSelectedPlan();
        let successMsg = 'Your subscription plan has been updated';
        if (!isTrial && isPlanChangeOperation()) {
            successMsg = 'Your subscription plan will be updated at the end of current subscription period';
        }

        const subscriptionDetails = new SubscriptionDetails(
            context.user,
            selectedPlan,
            null,
            !isTrial,
            paidUserCount,
            null,
            null,
            null
        );
        setIsLoading(true);

        try {
            const response = await update(context, subscriptionDetails);
            setIsLoading(false);
            if (isSuccess(response.status)) {
                getDialog().status(successMsg, refreshPlan);
            } else {
                getDialog().status(response.data.message);
                setShowPlanDdl(false);
                setSelectedPlanInDdl(selectedPlan);
            }
        } catch (error) {
            setIsLoading(false);
            getDialog().status(error);
        }
    };

    const upgradeTrialPlan = async () => {
        const { uiAccountSubscriptionInfo } = subscriptionResponse.data;
        const isTrial = uiAccountSubscriptionInfo.statusDisplayName === Display.STATUS_TRIAL;
        const selectedPlan = getSelectedPlan();
        const subscriptionDetails = new SubscriptionDetails(
            context.user,
            selectedPlan,
            null,
            !isTrial,
            paidUserCount,
            null,
            null,
            null
        );
        setIsLoading(true);

        try {
            const response = await update(context, subscriptionDetails);
            setIsLoading(false);
            if (isSuccess(response.status)) {
                getDialog().status('Your subscription plan has been updated', refreshPlan);
            } else {
                getDialog().status(response.data.message);
                setShowPlanDdl(false);
                setSelectedPlanInDdl(selectedPlan);
            }
        } catch (error) {
            setIsLoading(false);
            getDialog().status(error);
        }
    };

    const upgradePlan = () => {
        const { uiAccountSubscriptionInfo } = subscriptionResponse.data;
        const accountManagementData = new AccountManagementData(uiAccountSubscriptionInfo, selectedPlanInDdl);
        setAccountManagementData({ ...accountManagementData });
        history.push(`${accPath}/purchaseSummary`);
    };

    const confirmCancel = () => {
        const confirm = confirmElement.current;
        confirm.prompt('FormulaSuite functions that you (and guest and paid users in your account) use with your spreadsheet will not update values after cancellation. Do you want to continue?', goToConfirmCancel);
    };

    const confirmReactivate = () => {
        const isNonRenewing = Display.NON_RENEWING == uiAccountSubscriptionInfo.subscriptionStatus;
        if (isNonRenewing) {
            getDialog().prompt('Are you sure you want to reactivate?', async () => {
                setIsLoading(true);
                try {
                    await reactivateSubscription(context);
                    refreshPlan();
                } catch (e) {
                    getDialog().status(e.getMessage());
                }
            });
        } else {
            history.push(`${accPath}/confirmReactivation`);
        }
    };

    const goToConfirmCancel = () => {
        history.push(`${accPath}/confirmCancellation`);
    };

    const getMessages = () => {
        return ManageSubscription.messages;
    };

    const getDialog = () => {
        return confirmElement.current;
    };

    const resetPlan = () => {
        setSelectedPlanInDdl(selectedPlan);
    };

    const refreshPlan = () => {
        setShowPlanDdl(false);
        setIsLoading(true);
        getSubscriptionDetails();
    };

    const isPlanChangeOperation = () => {
        return showPlanDdl && selectedPlanInDdl !== selectedPlan;
    };

    const getSelectedPlan = () => {
        return isPlanChangeOperation() ? selectedPlanInDdl : selectedPlan;
    };

    const getStatusButton = () => {
        const { subscriptionStatus, statusDisplayName } = uiAccountSubscriptionInfo;
        if (statusDisplayName === Display.STATUS_TRIAL) {
            return (
                <Link id="convertTrial"
                    className="btn btn-sm btn-success"
                    style={{ height: '25px', fontSize: '14px', lineHeight: '0.5', paddingTop: 7 }}
                    name="change-quantity-am"
                    to={{
                        pathname: `${accPath}/purchaseSummary`,
                        data: {
                            selectedPlan: selectedPlan
                        }
                    }}
                >Buy now
                </Link>
            )
        } else if (isInactive(subscriptionStatus)) {
            return (
                <button id="reactivateBtn"
                    className="btn btn-sm btn-success"
                    style={{ height: '25px', fontSize: '14px', lineHeight: '0.5' }}
                    name="change-quantity-am" onClick={() => { confirmReactivate() }}>Reactivate
                </button>
            )
        } else {
            return null;
        }
    };

    const renderFn = () => {
        const displayDdl = showPlanDdl ? '' : 'none';
        const displayPlan = showPlanDdl ? 'none' : '';
        const { planName, noOfSeats, statusDisplayName } = uiAccountSubscriptionInfo;
        const { invitedUsers, uiAccountSubscriptionInfo: subscriptionInfo } = subscriptionResponse.data;
        return (
            <>
                <PanelTitle panelName="Manage Subscription" />

                <div id="planDetailsDiv" style={{ paddingLeft: '10px', paddingTop: '9px' }}>
                    <div className="container-fluid">
                        <div className="row" style={{ backgroundColor: '#ccc', fontWeight: 'bold' }}>
                            <div className="col-sm-12">Your current subscription</div></div>

                        <div id="billingFrequencySpan"
                            className="row plan-detail-row mt-1"
                            style={{ display: displayPlan }}>
                            <div className="col-md-4">Plan</div>
                            <div className="col-md-6">
                                <label id="billingFrequency">{planName}</label>
                            </div>
                            <div className="col-md-2">
                                {Display.CANCELLED === subscriptionInfo.subscriptionStatus ? null : (<a onClick={() => setShowPlanDdl(true)}
                                    id="showBillingFrequencyLink"
                                    className="blueLink"
                                    style={{ fontWeight: 'normal', fontSize: '13px' }}>Change
                                </a>)}

                            </div>
                        </div>
                        <div className="row plan-detail-row mt-1"
                            id="frequencyDdlSpan"
                            style={{ display: displayDdl }}>
                            <div className="col-md-4">Plan</div>
                            <div className="col-md-6">
                                <select id="planSelector"
                                    className="form-control form-control-sm"
                                    value={selectedPlanInDdl}
                                    onChange={(e) => { planSelectorChanged(e) }}>
                                    <option value="formulasuite_monthly_subscription">Formulasuite monthly subscription</option>
                                    <option value="formulasuite_annual_subscription">Formulasuite annual subscription</option>
                                </select>
                            </div>
                            <div className="col-md-2">
                                <a className="blueLink"
                                    onClick={() => setShowPlanDdl(false)}
                                    style={{ fontSize: '13px' }}>Close
                                </a>
                            </div>
                        </div>
                        <div className="row plan-detail-row" id="status-row">
                            <div className="col-md-4">Status</div>
                            <div className="col-md-6"
                                style={{ fontWeight: 'bold' }}>
                                <label className="d-inline-block mb-0" style={{ width: 45, marginRight: '7px', marginBottom: 0 }}>
                                    {statusDisplayName}
                                </label>
                                {getStatusButton()}

                            </div>
                            {(Display.CANCELLED === subscriptionInfo.subscriptionStatus
                                || Display.NON_RENEWING === subscriptionInfo.subscriptionStatus) ? (
                                null) : (<div className="col-md-2">
                                    <a className="blueLink"
                                        onClick={confirmCancel}
                                        style={{ fontWeight: 'normal', fontSize: '13px' }}>Cancel
                                     </a>
                                </div>)}

                        </div>
                        <div className="row plan-detail-row">
                            <div className="col-md-4">Number of paid users</div>
                            <div className="col-md-8" id="addon-quantity-parent" style={{ display: '' }}>
                                <span className="addon-quantity-am d-inline-block" addon-subscribed="false">
                                    <input id="noOfUsers"
                                        type="number"
                                        min="0"
                                        className="integerField quantity-field"
                                        value={paidUserCount}
                                        onChange={(e) => { setPaidUserCount(Math.abs(e.target.value)) }}
                                    />
                                </span>
                                <button id="changeQuantity"
                                    className="btn btn-sm btn-success"
                                    style={{ height: '25px', fontSize: '14px', lineHeight: '0.5', width: 67 }}
                                    name="change-quantity-am" onClick={changeQuantityClicked}>Change
                                </button>
                            </div>

                        </div>
                        <div className="row plan-detail-row" id="quantity-availability">
                            <div className="col-md-4"></div>
                            <div className="col-md-8">
                                <strong>You have used {invitedUsers ? invitedUsers.length : 0} of available {subscriptionInfo.totalAdditionalUsers} users</strong>
                            </div>
                        </div>
                        <div className="row plan-detail-row">
                            <div className="col-md-4">Next charge</div>
                            <div className="col-md-8"><strong>{nextChargeAt}</strong></div>
                        </div>

                    </div>
                </div>
                <Confirm ref={confirmElement} show={false} />
            </>
        );
    };

    return waitForLoad(isLoading, renderFn);
};



export default ManageSubscription;
