export const AddinLoginRequest = (emailAddress, password, ssoDetails) => {
    let addinLoginRequest = {};
    addinLoginRequest.emailAddress = emailAddress ? emailAddress.toLowerCase().trim() : emailAddress;
    addinLoginRequest.password = password;
    addinLoginRequest.ssoDetails = ssoDetails;
    return addinLoginRequest;
}

export const SSODetail = (ssoType, accessToken, tokenType, expiresIn, scope, refreshToken, idToken) => {
    let ssoDetails = {};
    ssoDetails.ssoType = ssoType;
    ssoDetails.accessToken = accessToken;
    ssoDetails.tokenType = tokenType;
    ssoDetails.expiresIn = expiresIn;
    ssoDetails.scope = scope;
    ssoDetails.refreshToken = refreshToken;
    ssoDetails.idToken = idToken;
    return ssoDetails;
}

export const AddinSignUpRequest = (emailAddress, password) => {
    let addinSignUpRequest = {};
    addinSignUpRequest.emailId = emailAddress ? emailAddress.toLowerCase().trim() : emailAddress;
    addinSignUpRequest.confirmEmailAddress = emailAddress;
    addinSignUpRequest.password = password;
    return addinSignUpRequest;
}

export const OTPRequest = (changeOwner, fromEmailId, toEmailId) => {
    let otpRequest = {};
    otpRequest.changeOwner = changeOwner;
    otpRequest.fromEmailId = fromEmailId ? fromEmailId.toLowerCase().trim() : fromEmailId;
    otpRequest.toEmailId = toEmailId ? toEmailId.toLowerCase().trim() : toEmailId;
    return otpRequest;

}

export const AddinInviteUserRequest = (invitedEmailIds, invitedBy, changeOwner, otp, password) => {
    const addinInviteUserRequest = {};
    addinInviteUserRequest.invitedEmailIds = invitedEmailIds;
    addinInviteUserRequest.invitedBy = invitedBy;
    addinInviteUserRequest.changeOwner = changeOwner;
    addinInviteUserRequest.otp = otp;
    addinInviteUserRequest.password = password;
    return addinInviteUserRequest;
}

export const ChangePasswordRequest = (userId, oldPassword, password, confirmPassword) => {
    const changePasswordRequest = {};
    changePasswordRequest.userId = userId;
    changePasswordRequest.oldPassword = oldPassword;
    changePasswordRequest.password = password;
    changePasswordRequest.confirmPassword = confirmPassword;
    return changePasswordRequest;
}

